import React from 'react'
import { Col, Row, Typography, Layout } from 'antd'

const { Content } = Layout;

function PrivacyPolicy() {
    return (
        <>

            <Row style={{ marginTop: "150px", marginBottom: "150px" }}>
                <Content className="contentStyle">
                    <Row justify="center">
                        <Col style={{ width: "100%", maxWidth: "780px" }}>
                            <Row>
                                <Typography style={{ fontSize: "40px", color: "#363636", fontFamily: "ralewayBold" }}>
                                    Privacy Policy
                            </Typography>
                            </Row>
                            <Row style={{ marginTop: "30px" }}>
                                <Typography style={{ fontSize: "16px", color: "#646768", fontFamily: "roboto" }}>
                                    Protecting your private information is our priority. This Statement of Privacy applies to www.snigdhtech.com and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to SnigdhTech. The SnigdhTech website is a Packing and Shipping Services site. By using the SnigdhTech website, you consent to the data practices described in this statement.
                            </Typography>
                            </Row>
                            <Row style={{ marginTop: "30px" }}>
                                <Typography style={{
                                    fontSize: "20px", color: "#363636", fontFamily: "raleway",
                                    fontWeight: 600,
                                }}>
                                    Collection of your Personal Information
                                </Typography>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Typography style={{ fontSize: "16px", color: "#646768", fontFamily: "roboto" }}>
                                    SnigdhTech may collect personally identifiable information, such as your name. If you purchase SnigdhTech's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction. We may gather additional personal or non-personal information in the future. Information about your computer hardware and software may be automatically collected by SnigdhTech . This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the SnigdhTech website.
                                    </Typography>
                            </Row>
                            <Row style={{ marginTop: "30px" }}>
                                <Typography style={{
                                    fontSize: "20px", color: "#363636", fontFamily: "raleway",
                                    fontWeight: 600,
                                }}>
                                    Children Under Thirteen
                                </Typography>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Typography style={{ fontSize: "16px", color: "#646768", fontFamily: "roboto" }}>
                                    SnigdhTech does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age, you must ask your parent or guardian for permission to use this website.
                                </Typography>
                            </Row>
                            <Row style={{ marginTop: "30px" }}>
                                <Typography style={{
                                    fontSize: "20px", color: "#363636", fontFamily: "raleway",
                                    fontWeight: 600,
                                }}>
                                    Opt-Out & Unsubscribe
                                </Typography>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Typography style={{ fontSize: "16px", color: "#646768", fontFamily: "roboto" }}>
                                    We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain information. Users may opt-out of receiving any or all communications from SnigdhTech by contacting us here.
                                </Typography>
                            </Row>
                        </Col>


                    </Row>
                </Content>
            </Row>
        </>
    )
}

export default PrivacyPolicy
