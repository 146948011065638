import React from "react";
import "./App.css";

import * as ROUTES from "./routes";
import { BrowserRouter, Route } from "react-router-dom";

import Footer from "./components/Footer";
import Landing from "./view/Landing";
import Company from "./view/Company";
import Inspire from "./components/Products/Inspire";
import Services from "./view/Services";
import Contact from "./view/Contact";
import ContactDemo from "./view/ContactDemo";
import Aspire from "./components/Products/Aspire";
import Insight from "./components/Products/Insight";
import PrivacyPolicy from "./view/PrivacyPolicy";
import FAQs from "./view/FAQs";
import ScrollToTop from "./components/scrollToTop";
import Header from "./view/Header";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Route exact path={ROUTES.LANDING} component={Landing} />
        <Route path={ROUTES.COMPANY} component={Company} />
        <Route path={ROUTES.INSPIRE} component={Inspire} />
        <Route path={ROUTES.ASPIRE} component={Aspire} />
        <Route path={ROUTES.INSIGHT} component={Insight} />
        <Route path={ROUTES.SERVICES} component={Services} />
        <Route path={ROUTES.CONTACT} component={Contact} />
        <Route path={ROUTES.CONTACTDEMO} component={ContactDemo} />
        <Route path={ROUTES.PRIVACYPOLICY} component={PrivacyPolicy} />
        <Route path={ROUTES.FAQs} component={FAQs} />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
