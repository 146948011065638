import React from 'react';
import { Row, Col, Typography, Layout } from 'antd';
import { Link, withRouter } from "react-router-dom";

import * as ROUTES from "../routes";

import mac from "../assets/mac.png";
import arrow from "../assets/Arrow.png";
import iphone from "../assets/iphone.png";
import ipad from "../assets/ipad.png";

const { Content } = Layout

function ProductSection() {
    return (
        <>




            <Row justify="start" style={{ height: "1000px", width: "100%", position: "absolute", zIndex: -1 }}>
                <Col style={{ width: "100%", marginTop: "100px", overflow: "hidden" }}>
                    <Typography
                        style={{
                            fontSize: "1000px",
                            color: "#1A94F2",
                            opacity: "4%",
                            marginLeft: "-300px",
                            zIndex: -1,
                            fontFamily: "ralewayBold",
                            transform: `rotate(25deg)`
                        }}>
                        S
                    </Typography>
                </Col>
            </Row>

            <Content style={{ marginBottom: "20px" }}>
                <Row justify="center">
                    <Col style={{ maxWidth: "600px" }}>
                        <Col xs={0} md={24}>
                            <Typography
                                style={{
                                    fontSize: "40px",
                                    fontFamily: "raleway",
                                    fontWeight: 600,
                                    color: "#074663",
                                    textAlign: "center",
                                    lineHeight: "111%"
                                }}
                            >
                                Our products are built for every type of business
                        </Typography>
                        </Col>
                        <Col xs={24} md={0}>
                            <Typography
                                style={{
                                    fontSize: "30px",
                                    fontFamily: "raleway",
                                    fontWeight: 600,
                                    color: "#074663",
                                    textAlign: "center",
                                    lineHeight: "111%"
                                }}
                            >
                                Our products are built for every type of business
                        </Typography>
                        </Col>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={24} style={{ maxWidth: "900px" }}>

                        {/* <Row justify="center" style={{ minHeight: "200px", marginTop: "30px" }}>


                            <Col span={24}>
                                <Row justify="center">
                                    <img
                                        src={mac}
                                        alt="SnigdhTech"
                                        style={{
                                            position: "relative",
                                            width: "270px",
                                            height: "150px",
                                            cursor: "pointer",
                                            zIndex: 1,
                                        }}
                                    />
                                </Row>
                            </Col>

                            <Col span={24}
                                style={{
                                    position: "relative",
                                    minHeight: "350px",
                                    marginTop: "-70px",
                                    padding: "40px",
                                    backgroundColor: `rgb(7, 70, 99, 0.06)`
                                }}
                            >
                                <Row justify="center" style={{ marginTop: "50px" }}>
                                    <Typography
                                        style={{
                                            color: "#363636",
                                            fontSize: "25px",
                                            fontWeight: 600
                                        }}
                                    >
                                        SnigdhTech ERP
                                    </Typography>
                                </Row>
                                <Row justify="center" style={{ marginTop: "15px" }}>
                                    <Typography
                                        style={{
                                            color: "#646768",
                                            fontSize: "16px",
                                            textAlign: "center",
                                            maxWidth: "600px"
                                        }}
                                    >
                                        SnigdhTech Enterprise Resources Planning (ERP) software comprises of all three INSIPIRE, ASPIRE, INSIGHT all in one. A software for overall management of companies and organizations through automated web systems and applications.
                                    </Typography>
                                </Row>
                                <Row justify="center" style={{ marginTop: "15px" }}>
                                    <Typography className="viewPage">
                                        View Page
                                    </Typography>

                                    <img
                                        src={arrow}
                                        alt="SnigdhTech"
                                        style={{
                                            margin: "7px",
                                            height: "15px",
                                        }}
                                    />

                                </Row>
                            </Col>

                        </Row> */}


                        <Row justify="center" style={{ minHeight: "200px", marginTop: "30px" }}>


                            <Col span={24}>
                                <Row justify="end">
                                    <img
                                        src={mac}
                                        alt="SnigdhTech"
                                        style={{
                                            right: "3%",
                                            position: "relative",
                                            width: "270px",
                                            height: "150px",
                                            cursor: "pointer",
                                            transform: `rotate(5deg)`,
                                            zIndex: 1
                                        }}
                                    />
                                </Row>
                            </Col>

                            <Col span={24}
                                style={{
                                    position: "relative",
                                    minHeight: "200px",
                                    marginTop: "-110px",
                                    padding: "40px",
                                    backgroundColor: "#F0F8FE",
                                }}
                            >
                                <Col xs={1} lg={0} style={{ marginBottom: "120px" }} />
                                <Col xs={24} lg={16}>
                                    <Row>
                                        <Typography
                                            style={{
                                                textAlign: "left",
                                                fontFamily: "raleway",
                                                fontWeight: 600,
                                                color: "#363636",
                                                fontSize: "24px"
                                            }}
                                        >
                                            INSPIRE
                                        </Typography>
                                    </Row>
                                        <Typography
                                            style={{
                                                fontFamily: "raleway",
                                                fontWeight:20,
                                                color: "#363636",
                                                fontSize: "12px"
                                            }}
                                        >
                                           For people management and HR analytics
                                        </Typography>
                                    <Row style={{ marginTop: "7px" }}>
                                        <Typography
                                            style={{
                                                textAlign: "left",
                                                color: "#646768",
                                                fontSize: "14px",
                                                fontFamily: "roboto",
                                            }}
                                        >
                                            A web based automated secured, efficient and user friendly cloud based ERP software for people management and HR analytics which processes the inserted data automatically, and help the company pull off all desired stats without using other applications and doing manual work.
                                        </Typography>
                                    </Row>

                                    <Col style={{ marginTop: "7px", width: "130px" }} >
                                        <Link to={ROUTES.INSPIRE}>
                                            <Row>
                                                <Typography className="viewPage">
                                                    View Page
                                                </Typography>

                                                <img
                                                    src={arrow}
                                                    alt="SnigdhTech"
                                                    style={{
                                                        margin: "7px",
                                                        height: "15px",
                                                    }}
                                                />

                                            </Row>
                                        </Link>
                                    </Col>

                                </Col>
                            </Col>

                        </Row>

                        <Row justify="center" style={{ minHeight: "200px", marginTop: "30px" }}>
                            <Col span={24}>
                                <Row justify="start">
                                    <img
                                        src={ipad}
                                        alt="SnigdhTech"
                                        style={{
                                            right: "2%",
                                            position: "relative",
                                            width: "316px",
                                            height: "206px",
                                            cursor: "pointer",
                                            transform: `rotate(-5deg)`,
                                            zIndex: 1,
                                        }}
                                    />
                                </Row>
                            </Col>

                            <Col span={24}
                                style={{
                                    position: "relative",
                                    minHeight: "200px",
                                    marginTop: "-110px",
                                    padding: "40px",
                                    backgroundColor: "#FDF6E8",
                                }}
                            >
                                <Col xs={1} lg={0} style={{ marginBottom: "120px" }} />
                                <Row justify="end">
                                    <Col xs={24} lg={16}>
                                        <Row>
                                            <Typography
                                                style={{
                                                    textAlign: "left",
                                                    fontFamily: "raleway",
                                                    fontWeight: 600,
                                                    color: "#363636",
                                                    fontSize: "24px"
                                                }}
                                            >
                                                ASPIRE
                        </Typography>
                                        </Row>
                                        <Typography
                                            style={{
                                                fontFamily: "raleway",
                                                fontWeight:20,
                                                color: "#363636",
                                                fontSize: "12px"
                                            }}
                                        >
                                          For operational efficiency and effectiveness
                                        </Typography>
                                        <Row style={{ marginTop: "7px" }}>
                                            <Typography
                                                style={{
                                                    textAlign: "left",
                                                    color: "#646768",
                                                    fontSize: "14px",
                                                    fontFamily: "roboto",
                                                }}
                                            >
                                                A quick, flexible and user friendly cloud based ERP software which synchronizes different business processes to increases efficiency and ensures operating effectiveness through integration.
                       </Typography>
                                        </Row>

                                        <Col style={{ marginTop: "7px", width: "130px" }} >
                                            <Link to={ROUTES.ASPIRE}>
                                                <Row>
                                                    <Typography className="viewPage">
                                                        View Page
                                                    </Typography>
                                                    <img
                                                        src={arrow}
                                                        alt="SnigdhTech"
                                                        style={{
                                                            margin: "7px",
                                                            height: "15px",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </Row>
                                            </Link>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                        <Row justify="center" style={{ minHeight: "200px", marginTop: "30px" }}>


                            <Col span={24}>
                                <Row justify="end">
                                    <img
                                        src={iphone}
                                        alt="SnigdhTech"
                                        style={{
                                            right: "4%",
                                            position: "relative",
                                            height: "250px",
                                            cursor: "pointer",
                                            transform: `rotate(5deg)`,
                                            zIndex: 1,
                                        }}
                                    />
                                </Row>
                            </Col>

                            <Col span={24}
                                style={{
                                    position: "relative",
                                    minHeight: "200px",
                                    marginTop: "-180px",
                                    padding: "40px",
                                    backgroundColor: "#F3FFF9",
                                }}
                            >
                                <Col xs={1} lg={0} style={{ marginBottom: "120px" }} />
                                <Col xs={24} lg={16}>
                                    <Row>
                                        <Typography
                                            style={{
                                                textAlign: "left",
                                                fontWeight: 600,
                                                color: "#363636",
                                                fontSize: "24px"
                                            }}
                                        >
                                            INSIGHT
                        </Typography>
                                    </Row>
                                    <Typography
                                            style={{
                                                fontFamily: "raleway",
                                                fontWeight:20,
                                                color: "#363636",
                                                fontSize: "12px"
                                            }}
                                        >
                                          For understanding business and managing facts
                                        </Typography>
                                    <Row style={{ marginTop: "7px" }}>
                                        <Typography
                                            style={{
                                                textAlign: "left",
                                                color: "#646768",
                                                fontSize: "14px",
                                                fontFamily: "roboto",
                                            }}
                                        >
                                            A reliable, capable and user friendly cloud based ERP software which offers deep understanding of business, managing facts and integrity of the company.                                         </Typography>
                                    </Row>
                                    <Col style={{ marginTop: "7px", width: "130px" }} >
                                        <Link to={ROUTES.INSIGHT}>
                                            <Row>
                                                <Typography className="viewPage">
                                                    View Page
                                                </Typography>
                                                <img
                                                    src={arrow}
                                                    alt="SnigdhTech"
                                                    style={{
                                                        margin: "7px",
                                                        height: "15px",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </Row>
                                        </Link>
                                    </Col>
                                </Col>
                            </Col>
                        </Row>

                    </Col>
                </Row>

            </Content>

        </>
    )
}

export default withRouter(ProductSection)
