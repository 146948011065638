import React, { useEffect, useState } from 'react';

import GetADemo from '../GetADemo';

import {
    Row,
    Col,
    Typography,
    Button,
    Layout
} from "antd";

import dots from "../../assets/dots.png";
import INSIGHT_Header from "../../assets/Images/INSIGHT_Header.png";
import insight_flexible from "../../assets/Images/insight_flexible.png";
import insight_agile from "../../assets/Images/insight_agile.png";
import insight_visual from "../../assets/Images/insight_visual.png";

import * as ROUTES from "../../routes";
import { Link } from "react-router-dom";

const { Content } = Layout;

function Insight() {

    const [windowWidth, setWindowWidth] = useState("");

    useEffect(() => {
        setWindowWidth(window.innerWidth);

        window.addEventListener("resize", updateWindowSize);
    }, []);

    const updateWindowSize = () => {
        setWindowWidth(window.innerWidth);
    };


    return (
        <div >
            <Row
                justify="center"
                style={{
                    backgroundColor: "#E5F3FE",
                    top: 0,
                    right: 0,
                    left: 0,
                    height: "400px",
                    width: "100%",
                    marginTop: "84px"
                }}>
                <Row style={{ width: "1%" }} justify="end">
                    <img
                        src={dots}
                        alt="SnigdhTech"
                        style={{
                            position: "absolute",
                            top: 200,
                            width: "300px",
                            height: "300px",
                            maxHeight: "100%",
                            cursor: "pointer",
                            marginTop: "9px",
                            marginLeft: "-200px"
                        }}
                    />
                </Row>
                <Col span={24} style={{ marginTop: "40px" }}>
                    <Row justify="center">

                        <Col
                            style={{
                                backgroundColor: "#FFFFFF",
                                paddingTop: "8px",
                                paddingBottom: "10px",
                                width: "156px",
                                height: "42px",
                                borderRadius: "30px"
                            }}>
                            <Typography style={{
                                textAlign: "center", color: "#1A94F2", fontFamily: "roboto",
                                fontWeight: 600, fontSize: "18px"
                            }}>
                                Insight
                            </Typography>
                        </Col>
                    </Row>

                    <Row justify="center" style={{ marginTop: "15px" }}>

                        <Col xs={22} sm={18} style={{ maxWidth: "600px" }}>
                            {windowWidth > 690 ? (
                                <Typography
                                    style={{
                                        color: "#074663",
                                        textAlign: "center",
                                        fontSize: "20px",
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                    }} >
                                    A reliable, capable and user friendly cloud based ERP software which offers deep understanding of business, managing facts and integrity of the company. INSIGHT software is an integrated system designed to understand the productivity and impact from business processes.
                                </Typography>) :
                                (<Typography
                                    style={{
                                        color: "#074663",
                                        textAlign: "center",
                                        fontSize: "14px",
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                    }} >
                                    A reliable, capable and user friendly cloud based ERP software which offers deep understanding of business, managing facts and integrity of the company. INSIGHT software is an integrated system designed to understand the productivity and impact from business processes.
                                </Typography>)}
                        </Col>
                    </Row>
                    <Row style={{ width: "100%", height: "50px" }} justify="center">
                        <Row gutter={16} justify="center" style={{ width: "100%", maxWidth: "400px" }}>
                            <Col xs={24} style={{ marginTop: "20px", }}>
                                <Row justify="center">
                                    <Button
                                        size="large"
                                        className="demoButton">
                                       <Link to={ROUTES.CONTACTDEMO}>Get a Demo</Link>
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Col xs={0} sm={24} style={{ zIndex: -1, position: "absolute", height: "350px", width: "100%", backgroundColor: "#E5F3FE" }} />
                    </Row>

                </Col>
                <Col span={24}>
                    <Row align="top" justify="center" style={{ width: "100%", marginTop: "80px" }}>
                        <img
                            src={INSIGHT_Header}
                            alt="SnigdhTech"
                            style={{
                                position: "relative",
                                width: "99%",
                                maxWidth: "1000px",
                                height: "auto",
                                maxHeight: "100%",
                                cursor: "pointer",
                            }}
                        />
                    </Row>
                </Col>
            </Row>

            <Col xs={0} sm={24} style={{ height: "250px" }} />
            <Col xs={0} md={24} style={{ height: "250px" }} />
            <Row style={{ width: "100%" }} justify="center">

                <Col span={24}
                    style={{
                        width: "60%",
                        marginTop: "250px",
                        backgroundColor: "#E5F3FE",
                    }}>


                    <Row justify="center" style={{ width: "100%" }}>

                        <Content style={{ paddingTop: "50px", paddingBottom: "50px", maxWidth: "1000px" }}>

                            <Typography style={{
                                color: "#363636", fontSize: "26px", fontFamily: "raleway",
                                fontWeight: 600, textAlign:"center"
                                }}>
                                Snigdh INSIGHT includes
                            </Typography>
                            
                            <Row justify="center" style={{ marginTop: "15px" }}>

                        <Col xs={22} sm={18} style={{ maxWidth: "600px" }}>
                            {windowWidth > 690 ? (
                                <Typography
                                    style={{
                                        color: "#074663",
                                        textAlign: "center",
                                        fontSize: "14px",
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                    }} >
                                   feedback mechanism, audit management, monitoring and evaluation, Key Performance Indicators and other quality control measures of the company.
                                </Typography>) :
                                (<Typography
                                    style={{
                                        color: "#074663",
                                        textAlign: "center",
                                        fontSize: "14px",
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                    }} >
                                    feedback mechanism, audit management, monitoring and evaluation, Key Performance Indicators and other quality control measures of the company.
                                </Typography>)}
                        </Col>
                    </Row>
                        </Content>

                    </Row>



                </Col>
            </Row>

            <Row style={{ marginTop: "100px" }} justify="center">
                <Typography style={{
                    color: "#074663", fontFamily: "raleway",
                    fontWeight: 600, fontSize: "40px", textAlign: "center"
                }}>
                    Why to Choose Insight?
                </Typography>
            </Row>
            <Row style={{ marginTop: "20px"}} justify="center">
            <Col xs={22} sm={18} style={{ maxWidth:"900px"}}>
                {windowWidth > 690 ? (
            <Typography
            style={{
                color: "#646768",
                fontSize: "16px",
                fontFamily: "roboto",
                textAlign:"center"
            }}
            >
               Snigdh INSIGHT is a reliable, capable and user friendly 
               cloud based ERP software which offers deep understanding 
               of business, managing facts and integrity of the company. 
               INSIGHT software is an integrated system designed to understand 
               the productivity and impact from business processes. It visualized 
               the key results and indicators in a manner that management can make 
               quick decision based on that.
        </Typography> ) :(
            <Typography
            style={{
                color: "#646768",
                fontSize: "14px",
                fontFamily: "roboto",
                textAlign:"center"
            }}
            >
               Snigdh INSIGHT is a reliable, capable and user friendly 
               cloud based ERP software which offers deep understanding 
               of business, managing facts and integrity of the company. 
               INSIGHT software is an integrated system designed to understand 
               the productivity and impact from business processes. It visualized 
               the key results and indicators in a manner that management can make 
               quick decision based on that.
        </Typography>
        )}
            </Col>
            </Row>
            <Row justify="center" style={{ marginTop: "70px" }}>
                <Content className="contentStyle">
                    <Row>
                        <Col lg={12} xs={24}>
                            <Col>
                                <Row justify="center" style={{ width: "100%" }}>
                                    <Col xs={24} style={{ marginTop: "60px", paddingLeft: "15px", paddingRight: "15px" }}>
                                        <Row justify="center">
                                            <Typography
                                                style={{
                                                    textAlign: "left",
                                                    color: "#074663",
                                                    fontSize: "26px",
                                                    fontFamily: "raleway",
                                                    fontWeight: 600,
                                                }}
                                            >
                                               Flexible and user friendly digital platform
                </Typography>
                                        </Row>
                                    </Col>
                                    <Col xs={24} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                        <Row justify="center" style={{ marginTop: "20px" }} >
                                            <Typography
                                                style={{
                                                    color: "#646768",
                                                    fontSize: "16px",
                                                    textAlign: "left",
                                                    fontFamily: "roboto"
                                                }}
                                            >
                                                Able to adapt to different circumstances with aesthetic appealing, menu driven, easy to navigate and user friendly interfaces which makes easy for end users to work with.
                        </Typography>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col xs={24} lg={12} style={{ padding: "20px" }}>
                            <img
                                src={insight_flexible}
                                alt="SnigdhTech"
                                style={{

                                    width: "100%",
                                    height: "auto",
                                }}
                            />
                        </Col>

                    </Row>
                </Content>

            </Row>

            <Row justify="center" style={{ marginTop: "70px" }}>
                <Content className="contentStyle">
                    <Row>

                        <Col xs={24} lg={12} style={{ paddingTop: "20px", paddingRight: "20px", paddingBottom: "20px" }}>
                            <img
                                src={insight_agile}
                                alt="SnigdhTech"
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    marginLeft: "-25px"
                                }}
                            />
                        </Col>
                        <Col lg={12} xs={24}>
                            <Row justify="center" style={{ width: "100%" }}>
                                <Col xs={24} style={{ marginTop: "60px", paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row justify="center">
                                        <Typography
                                            style={{
                                                textAlign: "left",
                                                color: "#074663",
                                                fontSize: "26px",
                                                fontFamily: "raleway",
                                                fontWeight: 600,
                                            }}
                                        >
                                           Quick and smart with agile methodology
                                </Typography>
                                    </Row>
                                </Col>
                                <Col xs={24} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row justify="center" style={{ marginTop: "20px" }} >
                                        <Typography
                                            style={{
                                                color: "#646768",
                                                fontSize: "16px",
                                                textAlign: "left",
                                                fontFamily: "roboto"
                                            }}
                                        >
                                            Development of using advance technology and adopting agile methodology, software operates quickly to manage business processes automatically and responsive to devices, premise or cloud.
                        </Typography>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>


                    </Row>
                </Content>

            </Row>

            <Row justify="center" style={{ marginTop: "70px" }}>
                <Content className="contentStyle">
                    <Row style={{ width: "60%" }} justify="end">
                        <img
                            src={dots}
                            alt="SnigdhTech"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "300px",
                                height: "300px",
                                maxHeight: "100%",
                                cursor: "pointer",
                                marginTop: "-100px",
                                marginBottom: "-100px",
                                zIndex: -1
                            }}
                        />
                    </Row>
                    <Row>

                        <Col lg={12} xs={24}>
                            <Row justify="center" style={{ width: "100%" }}>
                                <Col xs={24} style={{ marginTop: "60px", paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row justify="center">
                                        <Typography
                                            style={{
                                                textAlign: "left",
                                                color: "#074663",
                                                fontSize: "26px",
                                                fontFamily: "raleway",
                                                fontWeight: 600,
                                            }}
                                        >
                                            Visualization of the key results and indicators to show impacts
                                            </Typography>
                                    </Row>
                                </Col>
                                <Col xs={24} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row justify="center" style={{ marginTop: "20px" }} >
                                        <Typography
                                            style={{
                                                color: "#646768",
                                                fontSize: "16px",
                                                textAlign: "left",
                                                fontFamily: "roboto"
                                            }}
                                        >
                                            Visualization the results of key indicators in dynamic charts with pattern and trends to communicate information quickly and clearly for analysis and decision making.
                                        </Typography>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} lg={12} style={{ padding: "20px" }}>
                            <img
                                src={insight_visual}
                                alt="SnigdhTech"
                                style={{

                                    width: "100%",
                                    height: "auto",
                                }}
                            />
                        </Col>
                    </Row>
                </Content>

            </Row>
            <GetADemo />
        </div >
    )
}

export default Insight
