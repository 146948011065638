import React, { useState } from "react";
import { Row, Col, Typography, Input, Button, Layout } from "antd";
import fb from "../assets/fb.png";
import ig from "../assets/ig.png";
import twitter from "../assets/twitter.png";
import * as emailjs from "emailjs-com";
import Form from "antd/lib/form/Form";
import { Label } from "semantic-ui-react";
import "bootstrap/dist/css/bootstrap.min.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import americanExpress from '../assets/GetDemo/americanExpress.png'
// import dressForSuccess from '../assets/GetDemo/dressForSuccess.png'
// import loanBuilder from '../assets/GetDemo/loanbuilder1.png'
// import cornellWeill from '../assets/GetDemo/cornellWeill.png'
// import nbc from '../assets/GetDemo/nbc.svg'
// import taboola from '../assets/GetDemo/taboola.svg'

const { TextArea } = Input;
const { Content } = Layout;

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const onChanged = (e) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "phone") {
      setPhone(e.target.value);
    } else if (e.target.name === "message") {
      setMessage(e.target.value);
    } else return;
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const success = () => toast("Success!");
  const failed = () => toast("Failed!");

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lhfqnbe",
        "template_ny9scmk",
        e.target,
        "user_AKLUBXKjmZ8FblkDfBbxs"
      )
      .then(
        (result) => {

          success();

          resetForm();
        },
        (error) => {
          console.log(error.text);

          failed();
        }
      );
  }

  return (
    <>
      <Row
        style={{
          backgroundColor: "#E5F3FE",
          top: 0,
          right: 0,
          left: 0,
          width: "100%",
          paddingBottom: "70px",
          marginTop: "84px",
        }}
      >
        <Content className="contentStyle">
          <Row
            justify="center"
            style={{ width: "100%", maxWidth: "600px", marginTop: "20px" }}
          >
            <Col xs={24} sm={18}>
              <Typography
                style={{
                  color: "#074663",
                  fontSize: "14px",
                  fontFamily: "roboto",
                  fontWeight: 600,
                  textAlign: "Left",
                  marginTop: "70px",
                }}
              >
                &mdash; Contact Us
              </Typography>
            </Col>
          </Row>
          <Row
            justify="center"
            style={{ width: "100%", maxWidth: "600px", marginTop: "20px" }}
          >
            <Col xs={0} sm={18}>
              <Typography
                style={{
                  color: "#074663",
                  fontSize: "40px",
                  fontFamily: "ralewayBold",
                  textAlign: "Left",
                }}
              >
                Get In Touch
              </Typography>
            </Col>
            <Col xs={24} sm={0}>
              <Typography
                style={{
                  color: "#074663",
                  fontSize: "30px",
                  fontFamily: "ralewayBold",
                  textAlign: "Left",
                }}
              >
                Get In Touch
              </Typography>
            </Col>
          </Row>
          <Row
            justify="center"
            style={{ width: "100%", maxWidth: "500px", marginTop: "20px" }}
          >
            <Col xs={23} sm={18}>
              <Typography
                style={{
                  color: "#6B7C93",
                  fontSize: "16px",
                  fontFamily: "roboto",
                  textAlign: "Left",
                }}
              >
                We are here to help, with 24/7 support service desk. Ask us
                about the product, services, our resources, features and the
                free demo.
              </Typography>
              <Typography
                style={{
                  color: "#6B7C93",
                  fontSize: "16px",
                  fontFamily: "roboto",
                  textAlign: "Left",
                  marginTop: "15px",
                }}
              >
                Tell us about the problem that you are having with our product
                and services. Whatever help you need with.
              </Typography>
            </Col>
          </Row>
        </Content>
      </Row>

      <Row style={{ marginTop: "50px", marginBottom: "100px" }}>
        <Content className="contentStyle">
          {" "}
          <Row justify="center" style={{ width: "100%", marginTop: "20px" }}>
            <Col xs={24} sm={21}>
              <Row justify="space-between" style={{ marginTop: "20px" }}>
                <Col xs={24} md={10}>
                  <form onSubmit={sendEmail}>
                    <input type="hidden" name="contact_number" />

                    <div className="form-group">
                      <Label for="name" class="form-label">
                        Full Name
                      </Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Full Name"
                        name="name"
                        onChange={onChanged}
                        value={name}
                      />
                    </div>

                    <div className="form-group">
                      <Label for="email" class="form-label">
                        Email
                      </Label>
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={onChanged}
                        value={email}
                      />
                    </div>

                    <div className="form-group">
                      <Label for="phone" class="form-label">
                        Phone
                      </Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Phone"
                        name="phone"
                        onChange={onChanged}
                        value={phone}
                      />
                    </div>

                    <div className="form-group">
                      <Label for="message" className="form-label">
                        Message
                      </Label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        name="message"
                        onChange={onChanged}
                        value={message}
                      />
                    </div>

                    <input
                      type="submit"
                      value="Send"
                      class="btn btn-primary btn-block"
                    />
                    <ToastContainer />
                  </form>
                </Col>

                <Col md={13} xs={24}>
                  <Row>
                    <Col>
                      <Typography
                        style={{
                          fontFamily: "roboto",
                          fontSize: "15px",
                          textAlign: "left",
                          color: "#646768",
                          marginTop: "20px",
                        }}
                      >
                        Lalitpur, Kathmandu-Nepal
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "roboto",
                          fontSize: "15px",
                          textAlign: "left",
                          color: "#646768",
                        }}
                      >
                      +977 9846024945
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "roboto",
                          fontSize: "15px",
                          textAlign: "left",
                          color: "#646768",
                        }}
                      >
                        mail@snigdhtech.com
                      </Typography>
                    </Col>
                  </Row>
                  <Row justify="start">
                    <img
                      src={fb}
                      alt="logo"
                      style={{
                        height: "31px",
                        width: "31px",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    />
                    <img
                      src={ig}
                      alt="logo"
                      style={{
                        height: "31px",
                        width: "31px",
                        margin: "10px",
                      }}
                    />
                    <img
                      src={twitter}
                      alt="logo"
                      style={{
                        height: "31px",
                        width: "31px",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    />
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.0806701366805!2d85.3219657150351!3d27.683901482801623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19dfa80c3189%3A0xa764faa3acea49d1!2sSnigdhTech%20%26%20Business%20Solution%20Pvt.%20Ltd!5e0!3m2!1sen!2snp!4v1588499570838!5m2!1sen!2snp"
                      width="100%"
                      height="290px"
                      frameborder="0"
                      title={"Google map"}
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Row>
    </>
  );
}

export default Contact;
