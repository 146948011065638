import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Menu, Popover, Layout } from 'antd';


import Logo from "../assets/logo.png";
import { MenuOutlined, DownOutlined, CloseOutlined } from "@ant-design/icons";
import { Link, useLocation, withRouter } from "react-router-dom";

import arrow from "../assets/Arrow.png";
import * as ROUTES from "../routes";

const { Content } = Layout;
const { SubMenu } = Menu;


function Header(props) {
    let location = useLocation();

    const [windowWidth, setWindowWidth] = useState("");
    const [showNav, setShowNav] = useState(false);
    const [scroll, setScroll] = useState("0");
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        function listenScrollEvent(e) {
            setScroll(window.scrollY);
        }
        window.addEventListener('scroll', listenScrollEvent);
    }, [scroll]);

    useEffect(() => {
        setWindowWidth(window.innerWidth);

        window.addEventListener("resize", updateWindowSize);
    }, []);

    const updateWindowSize = () => {
        setWindowWidth(window.innerWidth);
    };
   const hide = () => {
          setVisible (false)
      };
    
     const handleVisibleChange = visible => {
        setVisible (visible)
      };

    const products = (

        <>
            <Row style={{
                width: "100%",
                maxWidth: "700px",
                padding: "5px 0px 5px 0px"
            }}>
                <Col xs={10} style={{ width: "300px", padding: " 20px", borderRight: "1px solid #F8F8F8" }}>

                    <Typography
                        style={{
                            color: "#074663",
                            fontWeight: 600,
                            fontSize: "20px",
                        }}
                    >
                        SnigdhTech ERP
                            </Typography>
                    <Typography
                        style={{
                            color: "#646768",
                            fontSize: "12px",
                            marginTop: "10px"
                        }}
                    >
                        SnigdhTech Enterprise Resources Planning (ERP) software comprises of all three INSIPIRE, ASPIRE, INSIGHT all in one
                            </Typography>
                    <Row style={{
                        marginTop: "5px"
                    }}>
                        <Typography className="viewPage" style={{ fontSize: "13px", color: "#1A94F2", }}>
                            View Page
                                                </Typography>
                        <img
                            src={arrow}
                            alt="SnigdhTech"
                            style={{
                                margin: "7px",
                                height: "12px",
                                cursor: "pointer",
                            }}
                        />
                    </Row>

                </Col>

                <Col xs={14} style={{ width: "300px", padding: 0 }}>

                    <Menu style={{ padding: "0px" }}>
                        <Menu.Item className="menuHover" key="Inspire" style={{ height: "65px" }}>
                            <Link to={ROUTES.INSPIRE}>

                                <Row>
                                    <Typography style={{ color: "#074663", fontSize: "18px", fontFamily: "roboto" }}>
                                        Inspire
                        </Typography>
                                </Row>
                                <Row >
                                    <Typography style={{ color: "#646768", fontSize: "12px", fontFamily: "roboto", lineHeight: "20px" }}>
                                        People Management and HR Analytics Software.
                        </Typography>
                                </Row>

                            </Link>
                        </Menu.Item>
                        <Menu.Item className="menuHover" key="Aspire" style={{ height: "80px" }}>
                            <Link to={ROUTES.ASPIRE}>

                                <Row>
                                    <Typography style={{ color: "#074663", fontSize: "18px", fontFamily: "roboto" }}>
                                        Aspire
                        </Typography>
                                </Row>
                                <Row>
                                    <Typography style={{ color: "#646768", fontSize: "12px", fontFamily: "roboto", lineHeight: "20px" }}>
                                        Synchronizes business processes and ensures operating effectiveness.
                        </Typography>
                                </Row>

                            </Link>
                        </Menu.Item>
                        <Menu.Item className="menuHover" key="Insight" style={{ height: "65px" }}>
                            <Link to={ROUTES.INSIGHT}>

                                <Row>
                                    <Typography style={{ color: "#074663", fontSize: "18px", fontFamily: "roboto" }}>
                                        Insight
                                        </Typography>
                                </Row>
                                <Row >
                                    <Typography style={{ color: "#646768", fontSize: "12px", fontFamily: "roboto", lineHeight: "20px" }}>
                                        Managing facts and Integrity of the company.
                                        </Typography>
                                </Row>

                            </Link>
                        </Menu.Item>
                    </Menu>
                </Col>
            </Row >
        </>

    );

    return (
        <>
            {windowWidth < 960 ? (
                <>
                    {
                        showNav ? (
                            <Row
                                style={{
                                    zIndex: 20,
                                    backgroundColor: "#E5F3FE",
                                    paddingTop: "10px",
                                    paddingRight: "20px",
                                    paddingLeft: "20px",
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    boxShadow: "0 1px 5px -2px grey",
                                }}
                                justify="space-between"
                            >
                                <Col>
                                    <Row justify="start" style={{ height: "40px" }}>

                                        <img
                                            src={Logo}
                                            alt="SnigdhTech"
                                            style={{
                                                height: "100%",
                                                maxHeight: "100%",
                                                cursor: "pointer",
                                                marginTop: "15px",
                                            }}
                                            onClick={() => props.history.push("/")}
                                        ></img>

                                    </Row>
                                </Col>
                                <Col>
                                    <CloseOutlined
                                        onClick={() => setShowNav(false)}
                                        style={{
                                            fontSize: "20px",
                                            padding: "10px",
                                            color: "#1A94F2",
                                            marginTop: "9px",
                                        }}
                                    />
                                </Col>
                                <Col span={24} style={{ marginTop: "30px", marginBottom: "30px" }}>
                                    <Menu
                                        mode="inline"
                                        style={{ backgroundColor: "#E5F3FE" }}
                                        onClick={() => { setShowNav(false) }}
                                    >
                                        <SubMenu title={<Typography style={{
                                            fontFamily: "roboto",
                                            fontSize: "17px",
                                        }}>Products</Typography>}>
                                            <Menu.Item
                                                style={{
                                                    backgroundColor: "#E5F3FE",
                                                    margin: "0px",
                                                    fontFamily: "roboto",
                                                    fontSize: "16px"
                                                }}>
                                                <Link to={ROUTES.INSPIRE}>
                                                    Inspire
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item
                                                style={{
                                                    backgroundColor: "#E5F3FE",
                                                    margin: "0px",
                                                    fontFamily: "roboto",
                                                    fontSize: "16px"
                                                }}>
                                                <Link to={ROUTES.ASPIRE}>Aspire</Link>
                                            </Menu.Item>
                                            <Menu.Item
                                                style={{
                                                    backgroundColor: "#E5F3FE",
                                                    margin: "0px",
                                                    fontFamily: "roboto",
                                                    fontSize: "16px"
                                                }}>
                                                <Link to={ROUTES.INSIGHT}>Insight</Link>
                                            </Menu.Item>
                                        </SubMenu>
                                        <Menu.Item style={{
                                            fontFamily: "roboto",
                                            fontSize: "17px",
                                        }}>
                                            <Link to={ROUTES.SERVICES}>
                                                Services
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item style={{
                                            fontFamily: "roboto",
                                            fontSize: "17px",
                                        }}>
                                            <Link to={ROUTES.COMPANY}>
                                                Company
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item style={{
                                            fontFamily: "roboto",
                                            fontSize: "17px",
                                        }}>
                                            <Link to={ROUTES.CONTACT}>
                                                Contact
                                            </Link>
                                        </Menu.Item>
                                    </Menu>
                                </Col>
                            </Row>
                        ) : (<Row
                            style={{
                                zIndex: 20,
                                backgroundColor: location.pathname === "/" ? (scroll < 3 ? 'transparent' : '#FFFFFF') : ('#FFFFFF'),
                                height: "85px",
                                paddingTop: "10px",
                                paddingRight: "20px",
                                paddingLeft: "20px",
                                position: "fixed",
                                top: 0,
                                right: 0,
                                left: 0,
                            }}
                            justify="space-between"
                        >
                            <Col>
                                <Row justify="start" style={{ height: "40px" }}>

                                    <img
                                        src={Logo}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "100%",
                                            maxHeight: "100%",
                                            cursor: "pointer",
                                            marginTop: "15px",
                                        }}
                                        onClick={() => props.history.push("/")}
                                    ></img>

                                </Row>
                            </Col>
                            <Col>

                                <MenuOutlined
                                    onClick={() => setShowNav(true)}
                                    style={{
                                        fontSize: "20px",
                                        padding: "10px",
                                        color: "#1A94F2",
                                        marginTop: "9px",
                                    }}
                                />

                            </Col>

                        </Row>)
                    }
                </>
            ) : (
                    <Row
                        style={{
                            zIndex: 20,
                            backgroundColor: location.pathname === "/" ? (scroll < 3 ? 'transparent' : '#FFFFFF') : ('#FFFFFF'),
                            height: "85px",
                            paddingTop: "10px",
                            paddingBottom: "0px",
                            position: "fixed",
                            top: 0,
                            right: 0,
                            left: 0,
                        }}

                    >
                        <Content className="contentStyle">
                            <Row justify="space-between">
                                <Col>
                                    <Row justify="start" style={{ height: "40px" }}>
                                        <img
                                            src={Logo}
                                            alt="SnigdhTech"
                                            style={{
                                                height: "100%",
                                                maxHeight: "100%",
                                                cursor: "pointer",
                                                marginTop: "12px",
                                            }}
                                            onClick={() => props.history.push("/")}
                                        ></img>
                                    </Row>

                                </Col>
                                <Col style={{ marginTop: "9px" }}>
                                    <Row justify="center">
                                        <Col>
                                            <Menu mode="horizontal" selectedKeys={[]} className="customMenu" style={{ fontSize: 17 }}>
                                                <Menu.Item className="customMenuItem" key="products" style={{ paddingBottom: "20px" }}>
                                                    <Popover placement="bottom" content={<a onClick={hide}>{products}</a>} trigger="click" visible={visible}   
                                                         onVisibleChange={handleVisibleChange}>

                                                        Products<DownOutlined style={{ marginRight: "-4px", marginLeft: "5px" }} />

                                                    </Popover>
                                                </Menu.Item>

                                                <Menu.Item className="customMenuItem" key="services">
                                                    <Link to={ROUTES.SERVICES}>Services</Link>
                                                </Menu.Item>
                                                <Menu.Item className="customMenuItem" key="company">
                                                    <Link to={ROUTES.COMPANY}>
                                                        Company
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item className="customMenuItem" key="contact">
                                                    <Link to={ROUTES.CONTACT}>
                                                        Contact
                                                </Link>
                                                </Menu.Item>
                                            </Menu>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Content>

                    </Row>
                )}

        </>
    )
}

export default withRouter(Header)
