import React, { useState, useEffect } from 'react'
import { Row, Col, Typography, Input, Menu, Collapse, Button } from 'antd'
import { LineOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import * as ROUTES from '../routes';
import { Link } from 'react-router-dom';
import dots from '../assets/dots.png'



function FAQs() {
    const [windowWidth, setWindowWidth] = useState("");

    useEffect(() => {
        setWindowWidth(window.innerWidth);

        window.addEventListener("resize", updateWindowSize);
    }, []);

    const updateWindowSize = () => {
        setWindowWidth(window.innerWidth);
    };

    const [top, setTop] = useState(10);

    const { Panel } = Collapse;
    const text = (
        <p style={{ paddingLeft: 24, fontFamily: "roboto", fontSize: "16px", color: "#646768" }}>
            First we will show you demo, identify your actual requirement and submit the financial proposal.
            Once confirmed by you, both of us will sign a software agreement and process for implementation.
        </p>
    );

    const headerStyle = {
        fontFamily: "raleway",
        fontWeight: 600,
        fontSize: "26px",
        color: "#074663",
        marginBottom: 20,
    }

    const panelHeader = {
        fontFamily: "raleway",
        fontWeight: 600,
        fontSize: "20px",
        color: "#363636"
    }
    return (
        <div>

            < Row
                justify="center"
                style={{
                    backgroundColor: "#E5F3FE",
                    top: 0,
                    right: 0,
                    left: 0,
                    height: "400px",
                    width: "100%",
                    minHeight: "max-content",
                    paddingLeft: "20px"
                }}>
                <Col xs={24} lg={24} style={{ height: "0px" }} />
                <Col xs={24} lg={24}>
                    <Row justify="space-between" >
                        <Row style={{ width: "100%" }}>
                            <Col xs={3} lg={3} />
                            <Col >
                                <Typography style={{ fontFamily: "roboto", fontSize: "14px", fontWeight: 600 }} ><LineOutlined /> About Us</Typography>
                            </Col>
                        </Row>

                        <Row justify="space-around" style={{ width: "100%" }}  >
                            <Col xs={0} sm={3} />
                            <Col xs={18} sm={14} md={11} lg={10} xl={7} >
                                <Typography
                                    style={{
                                        fontFamily: "ralewayBold",
                                        fontSize: "40px",
                                        letterSpacing: "0px",
                                        color: " #074663",
                                        opacity: 1
                                    }}>
                                    Hello, How can we help you?
                                    </Typography>
                            </Col>
                            <Col xs={0} sm={7} md={1} lg={1} xl={2} />
                            <Col xs={18} sm={12} md={8} lg={8} xl={8} style={{ marginTop: 50 }}>
                                <Input style={{ height: 50, }}
                                    placeholder="Search for FAQs"
                                    suffix={<SearchOutlined style={{ fontSize: "20px", color: "#959595", marginTop: "10px" }} />}
                                />
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={2} xl={3} />
                        </Row>
                    </Row>
                </Col>
            </Row>


            <Col xs={24} sm={0} style={{ marginTop: "-50px" }} />
            <Col sm={24} style={{ height: "100px" }} />

            <Row style={{ height: "auto" }}>
                <Col xs={2} sm={1} md={1} lg={2} />
                <Col xs={22} sm={7} md={6} lg={5} style={{ scrollBehavior: "smooth" }}>
                    {windowWidth > 575 ? (


                        <Menu
                            // onClick={handleClick}
                            fullWidth
                            defaultSelectedKeys={['4']}
                            mode="inline"
                            onClick={() => setTop(top + 10)}
                            style={{ position: "sticky", top: "90px" }}

                        >
                            <Menu.Item key="1">Services</Menu.Item>
                            <Menu.Item key="2">Business </Menu.Item>
                            <Menu.Item key="3">News and Updates </Menu.Item>
                            <Menu.Item key="4" ><a href="#pricing">Pricing </a></Menu.Item>
                            <Menu.Item key="5"><a href="#widgetAndExtentions"> Widget and Extention</a> </Menu.Item>
                            <Menu.Item key="6"> <a href="#accountInformation">Account Information</a> </Menu.Item>
                            <Menu.Item key="7"><a href="#billing">Billing</a></Menu.Item>
                            <Menu.Item key="8">Users and Roles </Menu.Item>
                            <Menu.Item key="9">Troubleshooting </Menu.Item>
                            <Menu.Item key="10">Tools ansd Resources </Menu.Item>
                        </Menu>


                    ) : (

                            <Menu
                                // onClick={handleClick}
                                fullWidth
                                defaultSelectedKeys={['1']}
                                mode="inline"

                            >
                                <Menu.Item key="1">Services</Menu.Item>
                                <Menu.Item key="2">Business </Menu.Item>
                                <Menu.Item key="3">News and Updates </Menu.Item>
                                <Menu.Item key="4" ><a href="#pricing">Pricing </a></Menu.Item>
                                <Menu.Item key="5"><a href="#widgetAndExtentions"> Widget and Extention</a> </Menu.Item>
                                <Menu.Item key="6"> <a href="#accountInformation">Account Information</a> </Menu.Item>
                                <Menu.Item key="7"><a href="#billing">Billing</a></Menu.Item>
                                <Menu.Item key="8">Users and Roles </Menu.Item>
                                <Menu.Item key="9">Troubleshooting </Menu.Item>
                                <Menu.Item key="10">Tools ansd Resources </Menu.Item>
                            </Menu>

                        )}
                </Col>
                <Col xs={24} sm={0} style={{ height: 50 }} />
                <Col xs={3} sm={1} md={2} lg={2} />
                <Col xs={20} sm={14} md={14} lg={14} style={{ width: "100%", scrollBehavior: "smooth" }}>
                    <section >
                        <div id="pricing" >
                            <Row style={{ width: "100%" }}>
                                <Col xs={24} >
                                    <Row >
                                        <Typography style={headerStyle}>Pricing</Typography>
                                    </Row>
                                    <Collapse
                                        bordered={false}
                                        defaultActiveKey={['1']}
                                        expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 90 : 0} />}
                                        expandIconPosition="right"
                                        style={{ backgroundColor: "#fff" }}
                                    >
                                        <Panel header={<Typography style={panelHeader}>How to get demo?</Typography>} key="1">
                                            {text}
                                        </Panel>
                                        <Panel header={<Typography style={panelHeader}>How to pay for services?</Typography>} key="2">
                                            {text}
                                        </Panel>
                                        <Panel header={<Typography style={panelHeader}>How contact for new products?</Typography>} key="3">
                                            {text}
                                        </Panel>
                                        <Panel header={<Typography style={panelHeader}>How contact for new products?</Typography>} key="3">
                                            {text}
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </div>


                        <div id="widgetAndExtentions">
                            <Col xs={24} style={{ height: 100 }} />
                            <Row style={{ width: "100%" }}>
                                <Col xs={24} >
                                    <Row >
                                        <Typography style={headerStyle}>Widget and Extensions</Typography>
                                    </Row>
                                    <Collapse
                                        bordered={false}
                                        expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 90 : 0} />}
                                        expandIconPosition="right"
                                        style={{ backgroundColor: "#fff" }}
                                    >
                                        <Panel header={<Typography style={panelHeader}>How to get demo?</Typography>} key="1">
                                            {text}
                                        </Panel>
                                        <Panel header={<Typography style={panelHeader}>How to pay for services?</Typography>} key="2">
                                            {text}
                                        </Panel>
                                        <Panel header={<Typography style={panelHeader}>How contact for new products?</Typography>} key="3">
                                            {text}
                                        </Panel>
                                        <Panel header={<Typography style={panelHeader}>How contact for new products?</Typography>} key="3">
                                            {text}
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </div>

                        <div id="accountInformation">
                            <Col xs={24} style={{ height: 100 }} />
                            <Row style={{ width: "100%" }}>
                                <Col xs={24} >
                                    <Row >
                                        <Typography style={headerStyle}>Account Information</Typography>
                                    </Row>
                                    <Collapse
                                        bordered={false}
                                        expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 90 : 0} />}
                                        expandIconPosition="right"
                                        style={{ backgroundColor: "#fff" }}
                                    >
                                        <Panel header={<Typography style={panelHeader}>How to get demo?</Typography>} key="1">
                                            {text}
                                        </Panel>
                                        <Panel header={<Typography style={panelHeader}>How to pay for services?</Typography>} key="2">
                                            {text}
                                        </Panel>
                                        <Panel header={<Typography style={panelHeader}>How contact for new products?</Typography>} key="3">
                                            {text}
                                        </Panel>
                                        <Panel header={<Typography style={panelHeader}>How contact for new products?</Typography>} key="3">
                                            {text}
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </div>

                        <div id="billing">
                            <Col xs={24} style={{ height: 100 }} />
                            <Row style={{ width: "100%" }}>
                                <Col xs={24} >
                                    <Row >
                                        <Typography style={headerStyle}>Billing</Typography>
                                    </Row>
                                    <Collapse
                                        bordered={false}
                                        expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 90 : 0} />}
                                        expandIconPosition="right"
                                        style={{ backgroundColor: "#fff" }}
                                    >
                                        <Panel header={<Typography style={panelHeader}>How to get demo?</Typography>} key="1">
                                            {text}
                                        </Panel>
                                        <Panel header={<Typography style={panelHeader}>How to pay for services?</Typography>} key="2">
                                            {text}
                                        </Panel>
                                        <Panel header={<Typography style={panelHeader}>How contact for new products?</Typography>} key="3">
                                            {text}
                                        </Panel>
                                        <Panel header={<Typography style={panelHeader}>How contact for new products?</Typography>} key="3">
                                            {text}
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </Col>

            </Row >


            <Row
                justify="center"
                style={{
                    marginTop: "100px",
                    height: "435px",
                    background: "transparent linear-gradient(358deg, #2383CC 0%, #1D6BA7 100%) 0% 0% no-repeat padding-box",
                    opacity: "87%"
                }}>
                <Col style={{ marginTop: "98px", padding: "10px" }}>
                    <Row style={{ width: "40%" }} justify="end">
                        <img
                            src={dots}
                            alt="SnigdhTech"
                            style={{
                                position: "absolute",
                                top: 0,
                                width: "300px",
                                height: "300px",
                                maxHeight: "100%",
                                cursor: "pointer",
                                marginTop: "9px",
                            }}
                        />
                    </Row>
                    <Row justify="center" style={{ width: "100%" }}>
                        <Col style={{ maxWidth: "500px" }}>
                            <Typography
                                style={{
                                    color: "#FFFFFF",
                                    fontSize: "38px",
                                    textAlign: "center",
                                    fontFamily: "raleway",
                                    fontWeight: 600,
                                    lineHeight: "111%"
                                }}
                            >
                                Ready to take your business started with us?
                                </Typography>
                        </Col>
                    </Row>
                    <Row justify="center" style={{ width: "100%", marginTop: "18px" }} >
                        <Col style={{ maxWidth: "350px" }}>
                            <Typography
                                style={{
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    fontFamily: "roboto",
                                }}
                            >
                                Request for demo, It is fast, easy and completely free
                        </Typography>
                        </Col>
                    </Row>
                    <Row justify="center" style={{ width: "100%", marginTop: "20px" }} >
                        <Col style={{ maxWidth: "350px" }}>
                            <Button
                                size="large"
                                style={{
                                    borderRadius: "4px",
                                    backgroundColor: "#E8F3FC",
                                    border: "solid 1px #1A94F2",
                                    color: "#1A94F2",
                                    width: "160px",
                                    height: "47px",
                                    boxShadow: "1px 3px 8px #00000024"
                                }}>
                                <Link to={ROUTES.CONTACTDEMO}>
                                    Get a Demo
                                    </Link>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div >
    )
}
export default FAQs;