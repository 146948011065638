import React from 'react';
import { Row, Col, Typography, Layout } from 'antd';

import onDemand from '../assets/onDemand.png';
import cloudBased from '../assets/cloudBased.png';
import personalization from '../assets/personalization.png';
import visualized from '../assets/visualized.png';
import simple from '../assets/simple.png';
import flexible from '../assets/flexible.png';
import dots from '../assets/dots.png';

const { Content } = Layout;


function CompanyFeatures() {
    return (
        <>

            <Content className="contentStyle">
                <Row justify="center">
                    <Col xs={24} style={{ maxWidth: "600px" }}>
                        <Col xs={0} md={24}>
                            <Typography
                                style={{
                                    fontSize: "40px",
                                    color: "#074663",
                                    textAlign: "center",
                                    fontFamily: "raleway",
                                    fontWeight: 600,
                                    lineHeight: "111%"
                                }}
                            >
                                Main features of the product that we develop
                                </Typography>
                        </Col>
                        <Col xs={24} md={0}>
                            <Typography
                                style={{
                                    fontSize: "30px",
                                    color: "#074663",
                                    textAlign: "center",
                                    fontFamily: "raleway",
                                    fontWeight: 600,
                                    lineHeight: "111%"
                                }}
                            >
                                Main features of the product that we develop
                                </Typography>
                        </Col>

                    </Col>
                </Row>
                <Row justify="center" style={{ marginTop: "30px" }}>
                    <Col xs={24} md={12} lg={8} style={{ marginTop: "20px" }}>
                        <Row>
                            <Col>
                                <img
                                    src={flexible}
                                    alt="SnigdhTech"
                                    style={{
                                        height: "63px",
                                        maxHeight: "63px",
                                        cursor: "pointer",
                                        marginTop: "9px"
                                    }}
                                />
                            </Col>
                            <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px", marginTop: "5px" }}>
                                <Row>
                                    <Typography style={{
                                        fontSize: "20px",
                                        textAlign: "left",
                                        color: "#363636",
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                    }}>
                                        Flexible and User Friendly
                                </Typography>
                                </Row>
                                <Row>
                                    <Typography style={{
                                        fontSize: "16px",
                                        textAlign: "left",
                                        color: "#646768",
                                        fontFamily: "roboto",
                                        paddingRight: "20px"
                                    }}>
                                        Able to adapt to different circumstances with aesthetic appealing, menu driven, easy to navigate and user friendly interfaces which makes easy for end users to work with.                                </Typography>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={12} lg={8} style={{ marginTop: "20px" }}>
                        <Row>
                            <Col>
                                <img
                                    src={personalization}
                                    alt="SnigdhTech"
                                    style={{
                                        height: "63px",
                                        maxHeight: "63px",
                                        cursor: "pointer",
                                        marginTop: "9px",
                                    }}
                                />
                            </Col>
                            <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px", marginTop: "5px" }}>                                <Row>
                                <Typography style={{
                                    fontSize: "20px",
                                    textAlign: "left",
                                    color: "#363636",
                                    fontFamily: "raleway",
                                    fontWeight: 600,
                                }}>
                                    Quick and Smart
                                </Typography>
                            </Row>
                                <Row>
                                    <Typography style={{
                                        fontSize: "16px",
                                        textAlign: "left",
                                        color: "#646768",
                                        fontFamily: "roboto",
                                        paddingRight: "20px"
                                    }}>
                                        Development of using advance technology and adopting agile methodology, software operates quickly to manage business processes automatically and responsive to devices, premise or cloud.                                </Typography>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={12} lg={8} style={{ marginTop: "20px" }}>
                        <Row>
                            <Col>
                                <img
                                    src={visualized}
                                    alt="SnigdhTech"
                                    style={{
                                        height: "63px",
                                        maxHeight: "63px",
                                        cursor: "pointer",
                                        marginTop: "9px",
                                    }}
                                />
                            </Col>
                            <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px", marginTop: "5px" }}>                                <Row>
                                <Typography style={{
                                    fontSize: "20px",
                                    textAlign: "left",
                                    color: "#363636",
                                    fontFamily: "raleway",
                                    fontWeight: 600,
                                }}>
                                    Infographics
                                    </Typography>
                            </Row>
                                <Row>
                                    <Typography style={{
                                        fontSize: "16px",
                                        textAlign: "left",
                                        color: "#646768",
                                        fontFamily: "roboto",
                                        paddingRight: "20px"
                                    }}>
                                        Visualization the results of key indicators in dynamic charts with pattern and trends to communicate information quickly and clearly for analysis and decision making.                                    </Typography>
                                </Row>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={24} md={12} lg={8} style={{ marginTop: "20px" }}>
                        <Row>
                            <Col>
                                <img
                                    src={onDemand}
                                    alt="SnigdhTech"
                                    style={{
                                        height: "63px",
                                        maxHeight: "63px",
                                        cursor: "pointer",
                                        marginTop: "9px",
                                    }}
                                />
                            </Col>
                            <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px", marginTop: "5px" }}>                                <Row>
                                <Typography style={{
                                    fontSize: "20px",
                                    textAlign: "left",
                                    color: "#363636",
                                    fontFamily: "raleway",
                                    fontWeight: 600,
                                }}>
                                    On Demand Use Price
                                    </Typography>
                            </Row>
                                <Row>
                                    <Typography style={{
                                        fontSize: "16px",
                                        textAlign: "left",
                                        color: "#646768",
                                        fontFamily: "roboto",
                                        paddingRight: "20px"
                                    }}>
                                        Pay as you use, no license fee and hardware costs and costs savings through business process automation throughout                                    </Typography>
                                </Row>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={24} md={12} lg={8} style={{ marginTop: "20px" }}>
                        <Row>
                            <Col>
                                <img
                                    src={simple}
                                    alt="SnigdhTech"
                                    style={{
                                        height: "63px",
                                        maxHeight: "63px",
                                        cursor: "pointer",
                                        marginTop: "9px",
                                    }}
                                />
                            </Col>
                            <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px", marginTop: "5px" }}>                                <Row>
                                <Typography style={{
                                    fontSize: "20px",
                                    textAlign: "left",
                                    color: "#363636",
                                    fontFamily: "raleway",
                                    fontWeight: 600,
                                }}>
                                    One Stop Solutions
                                    </Typography>
                            </Row>
                                <Row>
                                    <Typography style={{
                                        fontSize: "16px",
                                        textAlign: "left",
                                        color: "#646768",
                                        fontFamily: "roboto",
                                        paddingRight: "20px"
                                    }}>
                                        Offers an integrated enterprise resources planning (ERP) including range of products and services that all the requirements of customers can be managed in one place.                                    </Typography>
                                </Row>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={24} md={12} lg={8} style={{ marginTop: "20px", }}>
                        <Row>
                            <Col>
                                <img
                                    src={cloudBased}
                                    alt="SnigdhTech"
                                    style={{
                                        height: "63px",
                                        maxHeight: "63px",
                                        cursor: "pointer",
                                        marginTop: "9px",
                                    }}
                                />
                            </Col>
                            <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px", marginTop: "5px" }}>                                <Row>
                                <Typography style={{
                                    fontSize: "20px",
                                    textAlign: "left",
                                    color: "#363636",
                                    fontFamily: "raleway",
                                    fontWeight: 600,
                                }}>
                                    Cloud Based Integration
                                    </Typography>
                            </Row>
                                <Row>
                                    <Typography style={{
                                        fontSize: "16px",
                                        textAlign: "left",
                                        color: "#646768",
                                        fontFamily: "roboto",
                                        paddingRight: "20px"
                                    }}>
                                        Faster implementation and better business intelligence with live data through integrated system on cloud platform with strong data security                                    </Typography>
                                </Row>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Content>
            <Row style={{ width: "60%" }} justify="end">
                <img
                    src={dots}
                    alt="SnigdhTech"
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "300px",
                        height: "300px",
                        maxHeight: "100%",
                        cursor: "pointer",
                        marginLeft: "-100px",
                        marginBottom: "-100px",
                        zIndex: -1
                    }}
                />
            </Row>


        </>
    )
}

export default CompanyFeatures
