import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Timeline, Layout } from 'antd'
import dots from '../assets/dots.png'
import company from '../assets/company.png'
import companyDown from '../assets/companyDown.png'
import { CheckCircleTwoTone, LineOutlined } from '@ant-design/icons';
import howWeDo1 from '../assets/howWeDo1.png'
import howWeDo2 from '../assets/howWeDo2.png';
import GetADemo from "../components/GetADemo";

import Karuna from "../assets/GetDemo/Karuna.png";
import Kopila from "../assets/GetDemo/Kopila.jpeg";
import CSG from "../assets/GetDemo/CSG.jpeg";
import SoalteeFoamHouse from "../assets/GetDemo/SoalteeFoamHouse.jpeg";

function Company() {

    const [windowWidth, setWindowWidth] = useState("");

    useEffect(() => {
        setWindowWidth(window.innerWidth);

        window.addEventListener("resize", updateWindowSize);
    }, []);

    const updateWindowSize = () => {
        setWindowWidth(window.innerWidth);
    };

    const timelineTopography = {
        color: "#646768",
        fontSize: "14px",
        fontFamily: "roboto"
    }

    const timelineLabel = {
        fontFamily: "robotoMedium",
        fontSize: "14px",
        color: "#074663",
    }

    const typographyStyle = {
        color: "#646768",
        fontSize: "16px",
        fontFamily: "roboto",
        fontWeight: 600,
        marginTop: "15px"
    }

    const { Content } = Layout;

    return (
        <div>
            <Row
                justify="center"
                style={{
                    backgroundColor: "#E5F3FE",
                    top: 0,
                    right: 0,
                    left: 0,
                    width: "98vw",
                    minHeight: "max-content",
                    marginTop: "84px",
                    paddingBottom: "40px",

                }}
            >

                <Content className="contentStyle" >

                       <Row>
                         <Col xs={23} sm={23} lg={23}>
                             {windowWidth > 500 ? (
                            <Row>
                                    <Typography 
                                    style={{
                                         color: "#074663",
                                        fontFamily: "roboto",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        textAlign: "left",
                                        marginLeft:"60px",
                                        marginTop: "70px",
                                    }} 
                                    >
                                        &mdash; About Us
                                    </Typography>
                            </Row>
                             ) : (
                                <Row>
                                <Typography 
                                style={{
                                     color: "#074663",
                                    fontFamily: "roboto",
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    textAlign: "left",
                                    marginLeft:"125px",
                                    marginTop: "70px",
                                }} 
                                >
                                    &mdash; About Us
                                </Typography>
                        </Row>
                             )}

                            <Col xs={24} />

                            <Row justify="start" style={{marginLeft:"60px", marginRight:"200px"}}>
                                <Col xs={24} sm={24} md={12} lg={14} >
                                    {windowWidth > 500 ? (
                                        <>
                                            <Row justify="center" >
                                                <Typography
                                                    style={{
                                                        fontFamily: "ralewayBold",
                                                        fontSize: "40px",
                                                        letterSpacing: "0px",
                                                        color: " #074663",
                                                        opacity: 1,
                                                        //marginLeft: "-65px"
                                                    }}>
                                                    SnigdhTech Business Solution Private Limited
                                                 </Typography>
                                            </Row>

                                            <Row justify="center" >
                                                <Typography style={{
                                                    fontFamily: "roboto",
                                                    fontSize: "16px",
                                                   // marginLeft: "-65px"
                                                }}>
                                                    Our Vision is to create end-to-end solutions to increase efficiency,
                                                    advancement and commitment for collaboration to go beyond.
                                             </Typography>
                                           <Typography
                                                style={{
                                                    fontFamily: "roboto",
                                                    fontWeight: 600,
                                                    fontSize: "16px",
                                                    marginTop: 40,
                                                   // marginLeft: "-65px"
                                                }}>
                                                We provide leading edge smart business solutions and excellence in management consulting services.
                                                Our innovative idea, technical excellence, collaboration, courage and conviction goes a long way to 
                                                achieve the goal and go beyond normal.
                                            </Typography>
                                            </Row>
                                        </>
                                    ) : (
                                            <>
                                                <Row justify="center">
                                                    <Typography
                                                        style={{
                                                            fontFamily: "ralewayBold",
                                                            fontSize: "30px",
                                                            letterSpacing: "0px",
                                                            color: " #074663",
                                                            opacity: 1,
                                                            marginLeft: "65px"
                                                        }}>
                                                       SnigdhTech Business Solution Private Limited
                                                 </Typography>
                                                </Row>

                                                <Row justify="center">
                                                    <Typography style={{
                                                        fontFamily: "roboto",
                                                        fontSize: "14px",
                                                        marginTop: 10,
                                                        marginLeft: "65px"
                                                    }}>
                                                        Our Vision is to create end-to-end solutions to increase efficiency,
                                                        advancement and commitment for collaboration to go beyond.
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontFamily: "roboto",
                                                    fontWeight: 600,
                                                    fontSize: "16px",
                                                    marginTop: 40,
                                                    marginLeft: "65px"
                                                }}>
                                                   We provide leading edge smart business solutions and excellence in management consulting services. 
                                                   Our innovative idea, technical excellence, collaboration, courage and conviction goes a long way to 
                                                   achieve the goal and go beyond normal.
                                            </Typography>
                                                </Row>
                                            </>
                                        )}
                                </Col>

                                <Col xs={1} sm={0} />
                            </Row>
                    
                    <Row justify="end" style={{ width: "100%" }}>
                        
                            {windowWidth > 500 ? (
                                <Col xs={24} sm={20} md={13} lg={13}
                                style={{ position: "absolute"}}>
                                    <img
                                        src={dots}
                                        alt="SnigdhTech"
                                        style={{
                                            position: "absolute",
                                            top: 10,
                                            width: "auto",
                                            maxWidth: "1000px",
                                            height: "auto",
                                            maxHeight: "100%",
                                            cursor: "pointer",
                                            marginLeft: 0,
                                            zIndex: -1
                                        }}
                                    />
                               
                                    <img
                                        src={company}
                                        alt="SnigdhTech"
                                        style={{
                                            position: "relative",
                                            width: "75%",
                                            height: "auto",
                                            cursor: "pointer",
                                            objectFit: "contain",
                                            marginLeft: 40,
                                            marginTop: "-135px"
                                        }}
                                    />
                                </Col>
                            ) : (
                                <Col xs={24} sm={24} md={13} lg={13} 
                                style={{ position: "absolute", justify: "center"}}>
                                        <img
                                            src={dots}
                                            alt="SnigdhTech"
                                            style={{
                                                position: "absolute",
                                                top: 30,
                                                width: "auto",
                                                maxWidth: "1000px",
                                                height: "auto",
                                                maxHeight: "100%",
                                                cursor: "pointer",
                                                marginLeft: 50,
                                                zIndex: -1
                                            }}
                                        />
                                        <img
                                            src={company}
                                            alt="SnigdhTech"
                                            style={{
                                                position: "relative",
                                                width: "75%",
                                                height: "auto",
                                                cursor: "pointer",
                                                objectFit: "contain",
                                                marginLeft: 40,
                                                }}
                                        />
                        </Col>
                                )}
                    </Row>
                    
                    </Col>
                    </Row>
                </Content>
                
            </Row>

            <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0} style={{ height: 150 }} />
            <Col xs={0} sm={24} md={0} lg={0} xl={0} xxl={0} style={{ height: 300 }} />
            <Col xs={24} sm={0} md={24} lg={0} xl={0} xxl={0} style={{ height: 100 }} />
            <Col xs={0} sm={0} md={0} lg={24} xl={0} xxl={0} style={{ height: 170 }} />
            <Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={0} style={{ minHeight: 200, marginTop: "auto" }} />
            <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={24} style={{ height: 200 }} />
            
            <Content className="contentStyle">
                <Row justify="center" >
                    <Col xs={22} lg={20}>
                    {windowWidth > 575 ? (
                        <Row>
                            <Col xs={22} sm={8} md={7} lg={10} >
                                <Col lg={17}>
                                    <Typography
                                        style={{
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                            fontSize: "40px",
                                            letterSpacing: 0,
                                            color: "#074663",
                                            opacity: 1,
                                            textAlign: "left",
                                            marginTop: 20
                                        }}>
                                        Who are we as a Company
                                 </Typography>
                                </Col>
                            </Col>
                            <Col xs={24} sm={0} md={0} lg={0} xl={0} style={{ height: 20 }} />
                            <Col xs={22} sm={14} md={13} lg={14}>
                                <Typography
                                    style={{
                                        font: "roboto",
                                        fontSize: "16px",
                                        letterSpacing: 0,
                                        color: "#646768",
                                        opacity: 1,
                                        textAlign: "left",
                                    }}>
                                    SnigdhTech and Business Solution Private Limited is a Software Development and Business Consulting firm,
                                    we work with all industries including small and mid sized enterprises across private,
                                    government and non-governmental sectors providing edge smart business solutions and consulting services to create a secure,
                                    efficient and user-friendly automated applications increasing efficiency in resource management and saving costs.
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        letterSpacing: 0,
                                        textAlign: "left",
                                        color: "#000",
                                        opacity: 1,
                                        marginTop: 40
                                    }}>
                                    We were established to provide leading edge smart business solutions and excellence in management consulting services.
                                    Our corporate motto is “Think smart. Go beyond.”.
                                    We believe that innovative idea, technical excellence, collaboration,
                                    courage and conviction goes a long way to achieve the goal and go beyond normal.
                        </Typography>
                            </Col>
                            <Col lg={2} />
                        </Row>
                    ) : (
                        <Row >
                        <Col xs={22} sm={8} md={7} lg={10} >
                            <Col lg={17}>
                                <Typography
                                    style={{
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                        fontSize: "40px",
                                        letterSpacing: 0,
                                        color: "#074663",
                                        opacity: 1,
                                        textAlign: "left",
                                    }}>
                                    Who are we as a Company
                             </Typography>
                            </Col>
                        </Col>
                        <Col xs={24} sm={0} md={0} lg={0} xl={0} style={{ height: 20 }} />
                        <Col xs={22} sm={14} md={13} lg={14}>
                            <Typography
                                style={{
                                    font: "roboto",
                                    fontSize: "16px",
                                    letterSpacing: 0,
                                    color: "#646768",
                                    opacity: 1,
                                    textAlign: "left",
                                }}>
                                SnigdhTech and Business Solution Private Limited is a Software Development and Business Consulting firm,
                                we work with all industries including small and mid sized enterprises across private,
                                government and non-governmental sectors providing edge smart business solutions and consulting services to create a secure,
                                efficient and user-friendly automated applications increasing efficiency in resource management and saving costs.
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: "roboto",
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    letterSpacing: 0,
                                    textAlign: "left",
                                    color: "#000",
                                    opacity: 1,
                                    marginTop: 40
                                }}>
                                We were established to provide leading edge smart business solutions and excellence in management consulting services.
                                Our corporate motto is “Think smart. Go beyond.”.
                                We believe that innovative idea, technical excellence, collaboration,
                                courage and conviction goes a long way to achieve the goal and go beyond normal.
                    </Typography>
                        </Col>
                        <Col lg={2} />
                    </Row> 
                    )}
                    </Col>
                </Row>
            </Content>

            <Col xs={24} sm={0} md={0} style={{ height: "50px" }} />
            <Col xs={0} sm={24} md={0} style={{ height: "100px" }} />
            <Col xs={0} md={24} style={{ height: "150px" }} />

            <Row>
                <Row justify="end" style={{ width: "100vw", scrollX: "false" }}>
                    <Col xs={22} sm={16} md={18} lg={18} xl={18} xxl={14}
                        style={{
                            width: "90vw",
                            backgroundColor: "#E5F3FE",
                        }}>
                        <Content className="contentStyle">
                            <Row justify="center">
                                <Col xs={4} sm={8} md={4} lg={6} xl={3} xxl={3} />
                                <Col sm={16} md={14} lg={11} xxl={22}
                                    style={{
                                        paddingTop: "50px",
                                        paddingBottom: "50px",
                                        paddingLeft: "20px"
                                    }}>

                                    <Row>
                                        <Typography style={{
                                            color: "#363636", fontSize: "26px", fontFamily: "raleway",
                                            fontWeight: 600, textAlign: "start"
                                        }}>
                                            To Create an end-to-end solution, We follow our core values
                                     </Typography>
                                    </Row>
                                    <Row>
                                        <Typography style={typographyStyle}>
                                            <CheckCircleTwoTone style={{ fontSize: "20px", marginRight: "10px" }} /> Excellence
                                        </Typography>
                                    </Row>
                                    <Row>
                                        <Typography style={typographyStyle}>
                                            <CheckCircleTwoTone style={{ fontSize: "20px", marginRight: "10px" }} /> Innovation
                                     </Typography>
                                    </Row>
                                    <Row>
                                        <Typography style={typographyStyle}>
                                            <CheckCircleTwoTone style={{ fontSize: "20px", marginRight: "10px" }} /> Commitment
                                        </Typography>
                                    </Row>
                                    <Row>
                                        <Typography style={typographyStyle}>
                                            <CheckCircleTwoTone style={{ fontSize: "20px", marginRight: "10px" }} /> Collaboration
                                    </Typography>
                                    </Row>
                                    <Row>
                                        <Typography style={typographyStyle}>
                                            <CheckCircleTwoTone style={{ fontSize: "20px", marginRight: "10px" }} /> Trust
                                    </Typography>
                                    </Row>
                                    <Row>
                                        <Typography style={typographyStyle}>
                                            <CheckCircleTwoTone style={{ fontSize: "20px", marginRight: "10px" }} /> Courage
                                 </Typography>
                                    </Row>

                                </Col>

                            </Row>
                        </Content>
                    </Col>
                    <Col xs={24} sm={0} md={0} style={{ width: "100%", height: "380px" }} />
                    <Col xs={0} sm={24} md={0} style={{ width: "100%", height: "350px" }} />
                    <Col xs={0} sm={0} md={24} xl={0} style={{ width: "100%", height: "290px" }} />
                </Row>
            </Row>

            <Content className="contentStyle">
                <Row justify="start" style={{ width: "100%" }}>
                    {windowWidth > 575 ? (
                        <>
                            <Col xs={0} sm={0} lg={0} xl={24}
                                style={{
                                    position: "relative",
                                    marginTop: "-470px"
                                }}>
                                <img
                                    src={dots}
                                    alt="SnigdhTech"
                                    style={{
                                        position: "absolute",
                                        width: "320px",
                                        height: "290px",
                                        maxHeight: "100%",
                                        cursor: "pointer",
                                        zIndex: -1

                                    }}
                                />
                            </Col>
                            <Col xs={24} sm={0} md={0} lg={0} xl={10} xxl={13}
                                style={{
                                    position: "absolute",
                                    marginTop: "-380px",
                                    marginLeft: "40px"
                                }}>
                                <img
                                    src={companyDown}
                                    alt="SnigdhTech"
                                    style={{
                                        width: "90%",
                                        maxWidth: "900px",
                                        height: "100%",
                                        maxheight: "auto"
                                    }}
                                />
                            </Col>

                            {/* -------for lg only---------- */}
                            <Col md xl={0} xxxl={0} style={{
                                position: "relative",
                                marginTop: "-800px",
                            }}>
                                <img
                                    src={dots}
                                    alt="SnigdhTech"
                                    style={{
                                        position: "absolute",
                                        width: "320px",
                                        height: "290px",
                                        maxHeight: "100%",
                                        cursor: "pointer",
                                        zIndex: -1

                                    }}
                                />
                            </Col>
                            <Col xs={0} sm={0} md={10} lg={12} xl={0} xxl={0} style={{
                                position: "absolute",
                                marginTop: "-740px",
                                marginLeft: "100px"
                            }}>
                                <img
                                    src={companyDown}
                                    alt="SnigdhTech"
                                    style={{
                                        width: "90%",
                                        maxWidth: "900px",
                                        height: "100%",
                                        maxheight: "auto"
                                    }}
                                />
                            </Col>
                            {/* ---------lg end---------- */}


                            {/* -------for sm only-------- */}
                            <Col sm md={0} xl={0} xxxl={0} style={{
                                position: "relative",
                                marginTop: "-800px",
                            }}>
                                <img
                                    src={dots}
                                    alt="SnigdhTech"
                                    style={{
                                        position: "absolute",
                                        width: "320px",
                                        height: "290px",
                                        maxHeight: "100%",
                                        cursor: "pointer",
                                        zIndex: -1

                                    }}
                                />
                            </Col>
                            <Col xs={0} sm={10} md={0} lg={0} xl={0} xxl={0} style={{
                                position: "absolute",
                                marginTop: "-790px",
                                marginLeft: "70px"
                            }}>
                                <img
                                    src={companyDown}
                                    alt="SnigdhTech"
                                    style={{
                                        width: "110%",
                                        maxWidth: "1000px",
                                        height: "400px",
                                        maxheight: "auto"
                                    }}
                                />
                            </Col>
                            {/* ----------em end--------- */}
                        </>
                    ) : (
                            <>
                                <Col xs={24} style={{
                                    position: "relative",
                                    marginTop: "-550px",
                                }}>
                                    <img
                                        src={dots}
                                        alt="SnigdhTech"
                                        style={{
                                            position: "absolute",
                                            width: "320px",
                                            height: "290px",
                                            maxHeight: "100%",
                                            cursor: "pointer",


                                        }}
                                    />
                                </Col>
                                <Col xs={24} style={{
                                    position: "relative",
                                    marginTop: "-430px",
                                    marginLeft: "15px"
                                }}>
                                    <img
                                        src={companyDown}
                                        alt="SnigdhTech"
                                        style={{
                                            width: "98%",
                                            maxWidth: "400px",
                                            height: "90%",
                                            maxheight: "auto"
                                        }}
                                    />
                                </Col>
                            </>
                        )}
                </Row>
            </Content>
            <Col xs={24} sm={0} md={0} xl={0} style={{ marginTop: "50px" }} />
            <Col xs={0} sm={24} md={0} xl={0} style={{ marginTop: "-220px" }} />
            <Col xs={0} md={24} xl={0} style={{ marginTop: "-140px" }} />
            <Col xs={0} sm={0} md={0} lg={0} xl={24} style={{ height: 250 }} />

            <Content className="contentStyle">
                <Col xs={24} style={{ height: 0 }} />
                <Row justify="center"
                    style={{
                        height: "maxContent",
                        width: "100%",
                    }} >
                    <Col xs={22} sm={20} lg={20} >
                        <Row style={{ width: "100%" }}>
                            <Col xs={24}>
                                <Typography style={{
                                    fontFamily: "raleway",
                                    fontWeight: 600,
                                    fontSize: "40px",
                                    textAlign: "left",
                                    color: "#074663"
                                }}>
                                    How we do
                                </Typography>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            {/*  <Col xs={2} md={4} /> */}
                            <Col xs={22} sm={20} md={20} lg={10}>
                                <Typography style={{
                                    fontFamily: "roboto",
                                    fontSize: "16px",
                                    textAlign: "left",
                                    color: "#646768"
                                }}>
                                    We are a team of professional, experienced and qualified people
                                    who love to innovate and bring solutions oriented models.
                                    We follow agile methodologies to lead the way,
                                    find solutions through collaboration with cross-functional teams
                                    with different organizations and individual market experts.
                                   </Typography>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content >

            <Content className="contentStyle">
                <Col >
                    <Row justify="end" style={{ marginTop: "-220px" }}>
                        <Col xs={0} xl={7} style={{ padding: 40, top: "-25px" }} >
                            <img
                                src={howWeDo2}
                                alt="SnigdhTech"
                                style={{ position: "relative" }}
                            />
                        </Col>
                        <Col xs={0} xl={7} style={{ marginTop: 15 }}>
                            <img
                                src={howWeDo1}
                                alt="SnigdhTech"
                                style={{ position: "relative" }}
                            />
                        </Col>
                    </Row>
                </Col>

                {/* ---for lg-------- */}
                <Col >
                    <Row justify="end" style={{ marginTop: "0px" }}>
                        <Col xs={0} lg={6} xl={0}>
                            <img
                                src={howWeDo2}
                                alt="SnigdhTech"
                                style={{ position: "relative", marginLeft: "-55px" }}
                            />
                        </Col>
                        <Col xs={0} lg={6} xl={0} style={{}}>
                            <img
                                src={howWeDo1}
                                alt="SnigdhTech"
                                style={{ position: "relative" }}
                            />
                        </Col>
                    </Row>
                </Col>

                {/* ---for md-------- */}
                <Col >
                    <Row justify="start" style={{ marginTop: 230, marginLeft: "120px" }}>
                        <Col sm={0} md={18} lg={0}>
                            <Row >
                                <Col xs={0} md={12} lg={0}  >
                                    <img
                                        src={howWeDo2}
                                        alt="SnigdhTech"
                                        style={{ position: "relative", marginLeft: "-55px" }}
                                    />
                                </Col>
                                <Col xs={0} md={12} lg={0}>
                                    <img
                                        src={howWeDo1}
                                        alt="SnigdhTech"
                                        style={{ position: "relative" }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                {/* ---for sm-------- */}
                <Row justify="center" >
                    {windowWidth < 650 ? (<>
                        <Col xs={22} sm={10} md={0}  >
                            <img
                                src={howWeDo2}
                                alt="SnigdhTech"
                                style={{ position: "relative", width: "auto", maxWidth: "225px" }}
                            />
                        </Col>
                        <Col xs={0} sm={10} md={0}>
                            <img
                                src={howWeDo1}
                                alt="SnigdhTech"
                                style={{ position: "relative", width: "auto", maxWidth: "225px" }}
                            />
                        </Col>
                    </>
                    ) : (
                            <> <Col xs={22} sm={10} md={0}  >
                                <img
                                    src={howWeDo2}
                                    alt="SnigdhTech"
                                    style={{ position: "relative", width: "auto", maxWidth: "260px" }}
                                />
                            </Col>
                                <Col xs={0} sm={10} md={0}>
                                    <img
                                        src={howWeDo1}
                                        alt="SnigdhTech"
                                        style={{ position: "relative", width: "auto", maxWidth: "260px" }}
                                    />
                                </Col>
                            </>
                        )}
                </Row>
            </Content>

            <Col xs={24} sm={0} md={0} xl={0} style={{ marginTop: "50px" }} />
            <Col xs={0} sm={24} md={0} xl={0} style={{ marginTop: 50 }} />
            <Col xs={0} md={24} xl={0} style={{ marginTop: 50 }} />
            <Col xs={0} sm={0} md={0} lg={0} xl={24} style={{ marginTop: "-150px" }} />


            <Content className="contentStyle" >
                <Col xs={24} style={{ height: 0 }} />
                <Row justify="center"
                    style={{
                        height: "maxContent",
                        width: "90%",
                    }} >
                    <Col xs={22} sm={20} lg={20} >
                        <Row style={{ width: "100%" }}>
                            <Col xs={24}>
                                <Typography style={{
                                    fontFamily: "raleway",
                                    fontWeight: 600,
                                    fontSize: "40px",
                                    textAlign: "left",
                                    color: "#074663"
                                }}>
                                    Enterprises Who Trust Us
                                </Typography>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                        <Row justify="space-between">
                  <Col lg={3} style={{ margin: "15px 0px 0px 30px" }}>
                  <img
                    src={Kopila}
                    alt="logo"
                    style={{
                      height: "auto",
                      width: "auto",
                      marginTop: "35px",
                      borderRadius: "2px",
                    }}
                  />
                  </Col>
                  <Col lg={3} style={{ margin: "15px 0px 0px 30px" }}>
                  <img
                    src={CSG}
                    alt="logo"
                    style={{
                      height: "auto",
                      width: "auto",
                      marginTop: "35px",
                      borderRadius: "2px",
                    }}
                  />
                  </Col>
                  <Col lg={3} style={{ margin: "15px 0px 0px 30px" }}>
                  <img
                    src={SoalteeFoamHouse}
                    alt="logo"
                    style={{
                      height: "auto",
                      width: "auto",
                      marginTop: "35px",
                      borderRadius: "2px",
                    }}
                  />
                  </Col>
                  <Col lg={3} style={{ margin: "60px 0px 0px 30px" }}>
                  <img
                    src={Karuna}
                    alt="logo"
                    style={{
                      height: "auto",
                      width: "auto",
                      marginTop: "35px",
                      borderRadius: "2px",
                    }}
                  />
                  </Col>
                </Row>
                        </Row>
                    </Col>
                </Row>
            </Content >

            <Col xs={24} sm={0} md={0} xl={0} style={{ marginTop: "50px" }} />
            <Col xs={0} sm={24} md={0} xl={0} style={{ marginTop: 50 }} />
            <Col xs={0} md={24} xl={0} style={{ marginTop: 50 }} />
            <Col xs={0} sm={0} md={0} lg={0} xl={24} style={{ marginButtom: "300px" }} />
           {/*  <Content className="contentStyle">
                <Row justify="center">
                    <Col xs={24} sm={14} md={12} lg={10} >
                        <Typography
                            style={{
                                fontFamily: "raleway",
                                fontWeight: 600,
                                fontSize: "40px",
                                color: "#074663",
                                marginBottom: "50px",
                                textAlign: "center"
                            }}>
                            So far our Journey
                    </Typography>
                    </Col>
                </Row>
            </Content> */}

          {/*   <Content className="contentStyle">
                <Row justify="center">
                    <Col xs={22} sm={16} lg={16}>
                        <Timeline mode="alternate" pending={<p style={timelineLabel}>On Going the Awesome Work...</p>} >

                            <Timeline.Item label={<b>2018</b>} style={timelineTopography}>
                                <p style={timelineLabel}>Our Humble Beginnings</p>
                           Our company was born and we started working as hard and as quick as possible to deliver our first portfolio product.
                         </Timeline.Item >

                            <Timeline.Item label={<b>2019</b>} style={timelineTopography}>
                                <p style={timelineLabel}>INSPIRE is Born</p>
                           Our development team started working on the first product that is a Management Information System and very soon enough, it started taking wings.
                        </Timeline.Item>

                            <Timeline.Item label={<b>January-2020</b>} style={timelineTopography}>
                                <p style={timelineLabel}>INSPIRE GOES LIVE</p>
                            INSPIRE development concluded and it went live for our use with our happy clients.
                         </Timeline.Item>

                            <Timeline.Item label={<b>Feburary-2020</b>} style={timelineTopography}>
                                <p style={timelineLabel}>ASPIRE has Launched.</p>
                            Finally, Our company has established a very first product that captures the interest of different business. Also, it allows expansion of the business and entering into new ventures.
                         </Timeline.Item>

                        </Timeline>
                    </Col>
                </Row >
            </Content> */}

{/*             <Col xs={24} sm={0} style={{ width: "100%", height: "0px" }} />
            <Col xs={0} sm={24} style={{ width: "100%", height: "30px" }} /> */}

            {/*   <Content className="contentStyle">
                <Row justify="center"
                    style={{
                        height: "max-content",
                    }} >
                    <Col xs={22} sm={20} lg={20} xl={20}>
                        <Row gutter={24} justify="space-between" >
                            <Col xs={24} sm={12} md={8} lg={6} >
                                <Row style={{ marginTop: "30px", objectFit: "fill" }}>
                                    <Avatar shape="square" size={255} icon={<UserOutlined />}
                                        style={imageStyle} />
                                </Row>
                                <Row justify="start" style={{ paddingLeft: "5px" }}>
                                    <Typography.Text style={imageTextStyle}>Ram Bhadur Thapa</Typography.Text>
                                    <Typography.Text style={positionStyle}>ux/ui designer</Typography.Text>
                                </Row>
                                <Row justify="start" >
                                    <img
                                        src={fb}
                                        alt="logo"
                                        style={imageIconStyle}
                                    />
                                    <img
                                        src={ig}
                                        alt="logo"
                                        style={imageIconStyle}
                                    />
                                    <img
                                        src={twitter}
                                        alt="logo"
                                        style={imageIconStyle}
                                    />
                                </Row>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} >
                                <Row style={{ marginTop: "30px" }}>
                                    <Avatar shape="square" size={255} icon={<UserOutlined />}
                                        style={imageStyle} />
                                </Row>
                                <Row justify="start" style={{ paddingLeft: "5px" }}>
                                    <Typography.Text style={imageTextStyle}>Ram Bhadur Thapa</Typography.Text>
                                    <Typography.Text style={positionStyle}>ux/ui designer</Typography.Text>
                                </Row>
                                <Row justify="start" >
                                    <img
                                        src={fb}
                                        alt="logo"
                                        style={imageIconStyle}


                                    />
                                    <img
                                        src={ig}
                                        alt="logo"
                                        style={imageIconStyle}
                                    />
                                    <img
                                        src={twitter}
                                        alt="logo"
                                        style={imageIconStyle}
                                    />
                                </Row>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} >
                                <Row style={{ marginTop: "30px" }}>
                                    <Avatar shape="square" size={255} icon={<UserOutlined />}
                                        style={imageStyle} />
                                </Row>
                                <Row justify="start" style={{ paddingLeft: "5px" }}>
                                    <Typography.Text style={imageTextStyle}>Ram Bhadur Thapa</Typography.Text>
                                    <Typography.Text style={positionStyle}>ux/ui designer</Typography.Text>
                                </Row>
                                <Row justify="start" >
                                    <img
                                        src={fb}
                                        alt="logo"
                                        style={imageIconStyle}
                                    />
                                    <img
                                        src={ig}
                                        alt="logo"
                                        style={imageIconStyle}
                                    />
                                    <img
                                        src={twitter}
                                        alt="logo"
                                        style={imageIconStyle}
                                    />
                                </Row>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} >
                                <Row style={{ marginTop: "30px" }}>
                                    <Avatar shape="square" size={255} icon={<UserOutlined />}
                                        style={imageStyle} />
                                </Row>
                                <Row justify="start" style={{ paddingLeft: "5px" }}>
                                    <Typography.Text style={imageTextStyle}>
                                        Ram Bhadur
                                    </Typography.Text>
                                    <Typography.Text style={positionStyle}>
                                        ux/ui designer
                                    </Typography.Text>
                                </Row>
                                <Row justify="start" >
                                    <img
                                        src={fb}
                                        alt="logo"
                                        style={imageIconStyle}
                                    />
                                    <img
                                        src={ig}
                                        alt="logo"
                                        style={imageIconStyle}
                                    />
                                    <img
                                        src={twitter}
                                        alt="logo"
                                        style={imageIconStyle}
                                    />
                                </Row>
                            </Col>
                        </Row>

                        <Col xs={24} style={{ height: 50 }} />
                    </Col>
                </Row>
            </Content> */}


            <GetADemo />

        </div >

    )
}

export default Company
