import React from 'react';
import {
    Row, Col, Typography, Divider, Layout
} from 'antd';

import Logo from "../assets/logo.png";
import fb from "../assets/fb.png";
import ig from "../assets/ig.png";
import twitter from "../assets/twitter.png";
import { Link, withRouter } from "react-router-dom";

import * as ROUTES from "../routes";

const { Content } = Layout;

function Footer() {

    return (

        <>
            <Row
                style={{
                    backgroundColor: "#FFFFFF",
                    borderTop: "1px solid #E7E7E7",
                    textAlign: "center",
                    paddingTop: "20px",
                    bottom: "0",
                    width: "100%",
                }}
            >

                <Content className="contentStyle">
                    <Row>
                        <Col xs={0} md={8} lg={7}>
                            <Row justify="start">
                                <img
                                    src={Logo}
                                    alt="logo"
                                    style={{
                                        height: "50px",
                                        width: "200px",
                                        marginTop: "20px",
                                        borderRadius: "2px"
                                    }}
                                ></img>
                            </Row>
                            <Row justify="start" style={{ paddingLeft: "20px" }}>
                                <img
                                    src={fb}
                                    alt="logo"
                                    style={{
                                        height: "31px",
                                        width: "31px",
                                        margin: "10px",
                                        borderRadius: "2px"
                                    }}
                                />
                                <img
                                    src={ig}
                                    alt="logo"
                                    style={{
                                        height: "31px",
                                        width: "31px",
                                        margin: "10px",
                                        borderRadius: "2px"
                                    }}
                                />
                                <img
                                    src={twitter}
                                    alt="logo"
                                    style={{
                                        height: "31px",
                                        width: "31px",
                                        marginLeft: "10px",
                                        marginTop: "10px",
                                        borderRadius: "2px"
                                    }}
                                />

                            </Row>
                        </Col>
                        <Col xs={24} md={0} >
                            <Row justify="center">
                                <img
                                    src={Logo}
                                    alt="logo"
                                    style={{
                                        height: "50px",
                                        width: "200px",
                                        marginTop: "20px",
                                        borderRadius: "2px"
                                    }}
                                ></img>
                            </Row>
                            <Row justify="center">
                                <img
                                    src={fb}
                                    alt="logo"
                                    style={{
                                        height: "31px",
                                        width: "31px",
                                        margin: "10px",
                                        borderRadius: "2px"
                                    }}
                                />
                                <img
                                    src={ig}
                                    alt="logo"
                                    style={{
                                        height: "31px",
                                        width: "31px",
                                        margin: "10px",
                                        borderRadius: "2px"
                                    }}
                                />
                                <img
                                    src={twitter}
                                    alt="logo"
                                    style={{
                                        height: "31px",
                                        width: "31px",
                                        marginLeft: "10px",
                                        marginTop: "10px",
                                        borderRadius: "2px"
                                    }}
                                />

                            </Row>
                        </Col>
                        <Col xs={15} sm={8} lg={4} style={{ marginTop: "20px", paddingRight: "15px" }}>
                            <Row justify="start">
                                <Typography
                                    style={{
                                        color: "#363636",
                                        fontFamily: "roboto",
                                        fontWeight: 600,
                                        fontSize: 16,
                                        marginBottom: "20px"
                                    }}>
                                    Contact
                                                    </Typography>
                            </Row>
                            <Row justify="start" >
                                <Col span={24} >
                                    <Row justify="start">
                                        <Typography
                                            style={{
                                                fontSize: 16,
                                                color: "#363636",
                                                fontFamily: "roboto"
                                            }}>
                                            +977 9846024945
                                                            </Typography>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row justify="start" style={{ marginTop: "10px" }}>
                                        <Typography
                                            style={{
                                                fontSize: 16,
                                                color: "#363636",
                                                fontFamily: "roboto"
                                            }}>
                                            Jwagal, Lalitpur
                                                            </Typography>
                                    </Row>
                                </Col>
                                <Col span={24} style={{ marginTop: "10px" }}>
                                    <Row justify="start">
                                        <Typography
                                            style={{
                                                fontSize: 16,
                                                color: "#363636",
                                                fontFamily: "roboto"
                                            }}>
                                            mail@snigdhtech.com
                                                            </Typography>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={8} sm={8} lg={3} style={{ marginTop: "20px", paddingRight: "10px", paddingLeft: "15px" }}>
                            <Row justify="start">
                                <Typography
                                    style={{
                                        color: "#363636",
                                        fontFamily: "roboto",
                                        fontWeight: 600,
                                        fontSize: 16,
                                        marginBottom: "20px"
                                    }}>
                                    Company
                                                    </Typography>
                            </Row>
                            <Row justify="start">
                                <Col span={24}>
                                    <Row justify="start">

                                        <Link to={ROUTES.COMPANY}>
                                            <Typography
                                                className="footerLink">
                                                About
                                                                    </Typography>
                                        </Link>
                                    </Row>
                                </Col>
                                <Col span={24} style={{ marginTop: "10px" }}>
                                    <Row justify="start">
                                        <Typography
                                            className="footerLink">
                                            Career
                                                            </Typography>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row justify="start" style={{ marginTop: "10px" }}>

                                        <Link to={ROUTES.FAQs}>
                                            <Typography
                                                className="footerLink">
                                                FAQs
                                                                </Typography>
                                        </Link>

                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={15} sm={8} lg={4} style={{ marginTop: "20px", paddingRight: "15px" }}>
                            <Row justify="start">
                                <Typography
                                    style={{
                                        color: "#363636",
                                        fontFamily: "roboto",
                                        fontWeight: 600,
                                        fontSize: 16,
                                        marginBottom: "20px"
                                    }}>
                                    Services
                                 </Typography>
                            </Row>
                            <Row justify="start" >
                                <Col span={24} >
                                    <Row justify="start">
                                        <Typography
                                            style={{
                                                fontSize: 16,
                                                color: "#363636",
                                                fontFamily: "roboto"
                                            }}>
                                            Business Consulting
                                                            </Typography>
                                    </Row>
                                </Col>

                                <Col span={24} style={{ marginTop: "10px" }}>
                                    <Row justify="start">
                                        <Typography
                                            style={{
                                                fontSize: 16,
                                                color: "#363636",
                                                fontFamily: "roboto",
                                                textAlign: "left"
                                            }}>
                                            Research and Analytics
                                                            </Typography>
                                    </Row>
                                </Col>
                                <Col span={24} style={{ marginTop: "10px" }}>
                                    <Row justify="start">
                                        <Typography
                                            style={{
                                                fontSize: 16,
                                                color: "#363636",
                                                fontFamily: "roboto"
                                            }}>
                                            Managerial Services
                                        </Typography>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={8} sm={8} lg={3} style={{ marginTop: "20px", paddingLeft: "15px" }}>
                            <Row justify="start">
                                <Typography
                                    style={{
                                        color: "#363636",
                                        fontFamily: "roboto",
                                        fontWeight: 600,
                                        fontSize: 16,
                                        marginBottom: "20px"
                                    }}>
                                    Products
                                                    </Typography>
                            </Row>
                            <Row justify="start">
                                <Col span={24}>
                                    <Row justify="start">

                                        <Link to={ROUTES.INSPIRE}>
                                            <Typography
                                                className="footerLink">
                                                Inspire
                                                                    </Typography>
                                        </Link>

                                    </Row>
                                </Col>
                                <Col span={24} style={{ marginTop: "10px" }}>
                                    <Row justify="start">

                                        <Link to={ROUTES.ASPIRE}>
                                            <Typography className="footerLink">
                                                Aspire
                                                                </Typography>
                                        </Link>
                                    </Row>
                                </Col>
                                <Col span={24} style={{ marginTop: "10px" }}>
                                    <Row justify="start">
                                        <Link to={ROUTES.INSIGHT}>
                                            <Typography
                                                className="footerLink">
                                                Insight
                                                                    </Typography>
                                        </Link>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={14} sm={8} lg={3} style={{ marginTop: "20px" }}>
                            <Row justify="start">
                                <Typography
                                    style={{
                                        color: "#363636",
                                        fontFamily: "roboto",
                                        fontWeight: 600,
                                        fontSize: 16,
                                        marginBottom: "20px"
                                    }}>
                                    Legal
                                </Typography>
                            </Row>
                            <Row justify="start">
                                <Col span={24}>
                                    <Row justify="start">
                                        <Typography
                                            style={{
                                                textAlign: "left"
                                            }}
                                            className="footerLink">
                                            Terms and Conditions
                                                    </Typography>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row justify="start" style={{ marginTop: "10px" }}>
                                        <Link to={ROUTES.PRIVACYPOLICY} style={{ color: "#363636" }}>
                                            <Typography
                                                className="footerLink">
                                                Privacy Policy
                                                                </Typography>
                                        </Link>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Content>
            </Row>
            <Divider style={{ backgroundColor: "#707070", marginTop: "30px", marginBottom: "20px", opacity: "20%" }} />
            <Col span={24}>
                <Row justify="center">
                    <Typography style={{ color: "#6B7C93", fontSize: "12px", marginBottom: "20px" }}> &copy; SnigdhTech 2020</Typography>
                </Row>
            </Col>
        </>
    )
}

export default withRouter(Footer);