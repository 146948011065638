import React, { useEffect, useState }  from 'react';
import GetADemo from '../components/GetADemo';
import { Row, Col, Typography, Button, Layout } from 'antd';

import service from '../assets/Services/service.png';

import onDemand from '../assets/onDemand.png';
import cloudBased from '../assets/cloudBased.png';
import personalization from '../assets/personalization.png';
import visualized from '../assets/visualized.png';
import simple from '../assets/simple.png';
import flexible from '../assets/flexible.png';
import * as ROUTES from "../routes";
import { Link } from "react-router-dom";

const { Content } = Layout;

function Services() {

    const [windowWidth, setWindowWidth] = useState("");

    useEffect(() => {

        setWindowWidth(window.innerWidth);

        window.addEventListener("resize", updateWindowSize);
    }, []);

    const updateWindowSize = () => {
        setWindowWidth(window.innerWidth);
    };

    return (
        <>

            <Row
                style={{
                    backgroundColor: "#E5F3FE",
                    top: 0,
                    right: 0,
                    left: 0,
                    width: "100%",
                    marginTop: "84px"
                }}>
                <Content className="contentStyle">
                    <Row>
                        <Col xs={24}>

                            <Row>
                                <Typography
                                    style={{
                                        color: "#074663",
                                        fontSize: "14px",
                                        fontFamily: "roboto",
                                        fontWeight: 600,
                                        textAlign: "Left",
                                        marginTop: "70px"
                                    }}
                                >
                                    &#8212; Our Services
                                 </Typography>
                            </Row>
                            <Row style={{ width: "100%", maxWidth: "600px" }}>
                                <Col xs={0} sm={24}>
                                    <Typography
                                        style={{
                                            color: "#074663",
                                            fontSize: "40px",
                                            fontFamily: "ralewayBold",
                                            textAlign: "Left",
                                        }}
                                    >
                                        Specialized Technical Business Consulting
                                    </Typography>
                                </Col>
                                <Col xs={23} sm={0}>
                                    <Row justify="center">
                                        <Typography
                                            style={{
                                                color: "#074663",
                                                fontSize: "25px",
                                                fontFamily: "ralewayBold",
                                                textAlign: "Left",
                                            }}
                                        >
                                            Specialized Technical Business Consulting
                                        </Typography>
                                    </Row>
                                </Col>
                            </Row>

                            <Row justify="end" style={{ marginTop: "40px" }}>
                                <Col xs={24} lg={11}>
                                    <Row justify="start">
                                        <Col xs={0} sm={24} style={{ maxWidth: "500px" }}>
                                            <Typography
                                                style={{
                                                    color: "#363636",
                                                    fontSize: "26px",
                                                    fontFamily: "raleway",
                                                    fontWeight: 600,
                                                    textAlign: "left",
                                                }}
                                            >
                                                SnidghTech does Business Consulting, Research and Analytics and Managerial Services to get your company on the path of success
                                        </Typography>
                                        </Col>
                                        <Col xs={23} sm={0}>
                                            <Typography
                                                style={{
                                                    color: "#363636",
                                                    fontSize: "px",
                                                    fontFamily: "raleway",
                                                    fontWeight: 600,
                                                    textAlign: "left",
                                                }}
                                            >
                                                SnidghTech does Business Consulting, Research and Analytics and Managerial Services to get your company on the path of success

                                        </Typography>
                                        </Col>
                                    </Row>
                                    {windowWidth > 500 ? (
                                    <Row style={{ height: "70px", marginTop: "40px", marginBottom: "20px", zIndex: "2" }}>
                                        <Button
                                            size="large"
                                            className="demoButton"
                                        >
                                            <Link to={ROUTES.CONTACTDEMO}>Contact Us</Link>
                                        </Button>
                                    </Row>
                                    ) : (
                                        <Row style={{height: "70px", marginTop: "40px", marginBottom: "20px", zIndex: -1 }}>
                                        <Button
                                            size="large"
                                            className="demoButton"
                                        >
                                            <Link to={ROUTES.CONTACTDEMO}>Contact Us</Link>
                                        </Button>
                                    </Row>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Content>

            </ Row>
            <Content className="contentStyle">
                <Row justify="start" style={{ padding: "0px" }}>
                    <Col xs={0} lg={12}>
                        <Row justify="end" align="top">
                            <img
                                src={service}
                                alt="SnigdhTech"
                                style={{
                                    width: "700px",
                                    height: "400px",
                                    maxHeight: "100%",
                                    cursor: "pointer",
                                    zIndex: 2,
                                    position: "relative",
                                    marginTop: "-300px",
                                }}
                            />
                        </Row>
                    </Col>
                    <Col xs={24} md={18} lg={0}>
                        <img
                            src={service}
                            alt="SnigdhTech"
                            style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "100%",
                                cursor: "pointer",
                                zIndex: 2,
                                position: "relative",
                                marginTop: "-40px"
                            }}
                        />
                    </Col>
                </Row>
            </Content>


            <Row justify="center" style={{ marginTop: "100px" }}>
                <Content className="contentStyle">
                    <Row justify="center">
                        <Col xs={24} md={18} >
                            <Col xs={0} md={24}>
                                <Typography
                                    style={{
                                        fontSize: "30px",
                                        color: "#074663",
                                        textAlign: "center",
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                        lineHeight: "111%"
                                    }}
                                >
                                   We offer specialized technical consulting services through consultancy, training and other engagement to businesses in:
                                </Typography>
                            </Col>
                            <Col xs={24} md={0}>
                                <Typography
                                    style={{
                                        fontSize: "20px",
                                        color: "#074663",
                                        textAlign: "center",
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                        lineHeight: "111%"
                                    }}
                                >
                                   We offer specialized technical consulting services through consultancy, training and other engagement to businesses in:
                                </Typography>
                            </Col>

                        </Col>
                    </Row>

                    <Row justify="start" style={{ marginTop: "30px" }}>
                        <Col xs={24} md={12} lg={8} style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <img
                                        src={flexible}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "63px",
                                            maxHeight: "63px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Col>
                                <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px" }}>
                                    <Row >
                                        <Typography style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                            color: "#363636",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                        }}>
                                            Human resources management
                                </Typography>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={12} lg={8} style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <img
                                        src={personalization}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "63px",
                                            maxHeight: "63px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Col>
                                <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px", marginTop: "5px" }}>
                                    <Row>
                                        <Typography style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                            color: "#363636",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                        }}>
                                            Financial planning, analysis and reporting
                                </Typography>
                                    </Row>

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={12} lg={8} style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <img
                                        src={visualized}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "63px",
                                            maxHeight: "63px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Col>
                                <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px" }}>
                                    <Row>
                                        <Typography style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                            color: "#363636",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                        }}>
                                            Financial accounting, monitoring and control
                                    </Typography>
                                    </Row>

                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} md={12} lg={8} style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <img
                                        src={onDemand}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "63px",
                                            maxHeight: "63px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Col>
                                <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px" }}>
                                    <Row>
                                        <Typography style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                            color: "#363636",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                        }}>
                                            Supply chain management
                                    </Typography>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} md={12} lg={8} style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <img
                                        src={simple}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "63px",
                                            maxHeight: "63px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Col>
                                <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px" }}>
                                    <Row>
                                        <Typography style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                            color: "#363636",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                        }}>
                                            Training On Ms Excel
                                    </Typography>
                                    </Row>

                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} md={12} lg={8} style={{ marginTop: "30px", }}>
                            <Row>
                                <Col>
                                    <img
                                        src={cloudBased}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "63px",
                                            maxHeight: "63px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Col>
                                <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px" }}>
                                    <Row>
                                        <Typography style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                            color: "#363636",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                        }}>
                                            Project design and development
                                    </Typography>
                                    </Row>

                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} md={12} lg={8} style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <img
                                        src={flexible}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "63px",
                                            maxHeight: "63px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Col>
                                <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px" }}>
                                    <Row >
                                        <Typography style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                            color: "#363636",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                        }}>
                                            Tax planning and compliance management
                                </Typography>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={12} lg={8} style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <img
                                        src={personalization}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "63px",
                                            maxHeight: "63px",
                                            cursor: "pointer",

                                        }}
                                    />
                                </Col>
                                <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px", marginTop: "5px" }}>
                                    <Row>
                                        <Typography style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                            color: "#363636",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                        }}>
                                            Enterprise risk management
                                </Typography>
                                    </Row>

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={12} lg={8} style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <img
                                        src={visualized}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "63px",
                                            maxHeight: "63px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Col>
                                <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px" }}>
                                    <Row>
                                        <Typography style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                            color: "#363636",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                        }}>
                                            Development of policies and procedures
                                    </Typography>
                                    </Row>

                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} md={12} lg={8} style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <img
                                        src={onDemand}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "63px",
                                            maxHeight: "63px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Col>
                                <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px" }}>
                                    <Row>
                                        <Typography style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                            color: "#363636",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                        }}>
                                            Survey and analysis in different sectors
                                    </Typography>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} md={12} lg={8} style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <img
                                        src={simple}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "63px",
                                            maxHeight: "63px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Col>
                                <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px" }}>
                                    <Row>
                                        <Typography style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                            color: "#363636",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                        }}>
                                            Development of Business Plan
                                    </Typography>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} md={12} lg={8} style={{ marginTop: "30px" }}>
                            <Row>
                                <Col>
                                    <img
                                        src={cloudBased}
                                        alt="SnigdhTech"
                                        style={{
                                            height: "63px",
                                            maxHeight: "63px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Col>
                                <Col xs={17} sm={19} md={17} lg={16} xl={18} xxl={19} style={{ marginLeft: "15px" }}>
                                    <Row>
                                        <Typography style={{
                                            fontSize: "20px",
                                            textAlign: "left",
                                            color: "#363636",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                        }}>
                                           Information System Risk Management
                                    </Typography>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </Content>

            </Row>
            <GetADemo />
        </>
    )
}

export default Services
