import React, {  useEffect,useState } from "react";
import { Row, Col, Typography, Input, Button, Layout } from "antd";
import Karuna from "../assets/GetDemo/Karuna.png";
import Kopila from "../assets/GetDemo/Kopila.jpeg";
import CSG from "../assets/GetDemo/CSG.jpeg";
import SoalteeFoamHouse from "../assets/GetDemo/SoalteeFoamHouse.jpeg";

import { Label } from "semantic-ui-react";
import { Select } from "@material-ui/core";

function ContactDemo() {

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", updateWindowSize);
  }, []);

  const [windowWidth, setWindowWidth] = useState("");

  const [ name, setName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ message, setMessage ] = useState("");
  const [ inspireSelect, setInspireSelect]= useState(0);
  const [ aspireSelect, setAspireSelect]= useState(false);
  const [ insightSelect, setInsightSelect] = useState(false);

  const onChanged = (e) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "phone") {
      setPhone(e.target.value);
    } else if (e.target.name === "message") {
      setMessage(e.target.value);
    } else return;
  };

  const onInsightButtonSelect=()=>{
      setInsightSelect(!insightSelect);
  }

  const onInspireButtonSelect=()=>{
    setInspireSelect(!inspireSelect);
  }

  const onAspireButtonSelect=()=>{
    setAspireSelect(!aspireSelect)
  }

  const updateWindowSize = () => {
    setWindowWidth(window.innerWidth);
  };
  const { Content } = Layout;

  const InputStyle = {
    borderRadius: "4px",
    border: "1px solid #C5CBCF",
    marginTop: "15px",
    height: 50,
  };

  const buttonStyle = {
    fontSize: "14px",
    fontFamily: "roboto",
    background: "#E5F3FE 0% 0% no-repeat padding-box",
    borderRadius: "2px",
    opacity: 1,
    margin: "5px 10px 5px 0px",
    height: "37px",
  };

  const iconStyle = {
    height: "auto",
    width: "auto",
    marginTop: "35px",
    borderRadius: "2px",
  };

  return (
    <div>
      {windowWidth > 768 ? (
        <>
          <Content className="contentStyle">
            <Row justify="center">
              <Col xs={24} style={{ height: "100px" }} />
              <Col md={18}>
                <Row>
                  <Typography
                    style={{
                      fontFamily: "ralewayBold",
                      fontSize: "40px",
                      letterSpacing: " 0px",
                      color: "#074663",
                      opacity: 1,
                    }}
                  >
                    Get a Demo
                  </Typography>
                </Row>
              </Col>
              <Col md={18}>
                <Row style={{ width: "50%" }}>
                  <Typography
                    style={{
                      fontFamily: "roboto",
                      fontSize: "16px",
                      color: "#646768",
                    }}
                  >
                    We are ready to set up free trial for you. No credit card
                    required. We will contact you in the contact details
                    provided below.
                  </Typography>
                </Row>
              </Col>
            </Row>
          </Content>
          <Col xs={24} style={{ height: "80px" }} />
          <Content className="contentStyle">
            <Row justify="center" style={{ width: "100%", marginTop: "20px" }}>
              <Col xs={24} md={10} style={{marginBottom: "20px"}}>
                <form>
                  <input type="hidden" name="contact_number" />

                  <div className="form-group">
                    <Label for="name" class="form-label">
                      Full Name
                    </Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Full Name"
                      name="name"
                      onChange={onChanged}
                      value={name}
                    />
                  </div>

                  <div className="form-group">
                    <Label for="email" class="form-label">
                      Email
                    </Label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={onChanged}
                      value={email}
                    />
                  </div>

                  <div className="form-group">
                    <Label for="phone" class="form-label">
                      Phone
                    </Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      onChange={onChanged}
                      value={phone}
                    />
                  </div>

                  <Row justify="flex-start" style={{marginBottom: "5px"}}>
                    <Col md={18}>
                      <Row>
                        <Typography
                          style={{
                            fontfamily: "roboto",
                            fontSize: "16px",
                            color: "#646768",
                            marginTop: 0,
                          }}
                        >
                          Demo For :
                        </Typography>
                      </Row>
                      <Row style={{ height: 7 }} />
                      <Row>
                        <Col style={{marginRight:"10px", marginTop:"5px"}}>
                        <form>
                          <button
                            type="button"
                            onClick={onInspireButtonSelect}
                            class= {inspireSelect? "btn btn-primary btn-block":"btn btn-secondary btn-block"}
                            
                          >
                            INSPIRE
                          </button>
                        </form>
                        </Col>
                       
                        <Col style={{marginRight:"10px", marginTop:"5px"}}>
                        <form>
                          <button
                            type="button"
                         
                          onClick={onAspireButtonSelect}
                          class= {aspireSelect? "btn btn-primary btn-block":"btn btn-secondary btn-block"}
                          >
                            ASPIRE
                          </button>
                        </form>
                        </Col>
                     
                        <Col style={{marginRight:"10px", marginTop:"5px"}} >
                        <form>
                          <button
                            type="button"
                          onClick={onInsightButtonSelect}
                          class= {insightSelect? "btn btn-primary btn-block" : "btn btn-secondary btn-block"}
                          >
                            INSIGHT
                          </button>
                        </form>
                        </Col>
    
                      </Row>
                    </Col>
                  </Row>

                  <div className="form-group">
                    <Label for="message" className="form-label">
                      Message
                    </Label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                      name="message"
                      onChange={onChanged}
                      value={message}
                    />
                  </div>

                  <input
                    type="submit"
                    value="Send"
                    class="btn btn-primary btn-block"
                  />
                </form>
              </Col>

              <Col md={14} xs={24}>
                <Row style={{ margin: "15px 0px 0px 150px" }}>
                  <Typography
                    style={{
                      fontFamily: "raleway",
                      fontSize: "20px",
                      color: "#074663",
                      textAlign: "left",
                    }}
                  >
                    Enterprises who trusts Us
                  </Typography>
                </Row>
                <Row justify="space-between">
                  <Col lg={6} style={{ margin: "15px 0px 0px 150px" }}>
                  <img
                    src={Kopila}
                    alt="logo"
                    style={{
                      height: "auto",
                      width: "auto",
                      marginTop: "35px",
                      borderRadius: "2px",
                    }}
                  />
                  </Col>
                  <Col lg={6} style={{ margin: "15px 0px 0px 150px" }}>
                  <img
                    src={CSG}
                    alt="logo"
                    style={{
                      height: "auto",
                      width: "auto",
                      marginTop: "35px",
                      borderRadius: "2px",
                    }}
                  />
                  </Col>
                  <Col lg={6} style={{ margin: "15px 0px 0px 150px" }}>
                  <img
                    src={SoalteeFoamHouse}
                    alt="logo"
                    style={{
                      height: "auto",
                      width: "auto",
                      marginTop: "35px",
                      borderRadius: "2px",
                    }}
                  />
                  </Col>
                  <Col lg={7} style={{ margin: "15px 0px 0px 150px" }}>
                  <img
                    src={Karuna}
                    alt="logo"
                    style={{
                      height: "auto",
                      width: "auto",
                      marginTop: "35px",
                      borderRadius: "2px",
                    }}
                  />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Content>
        </>
      ) : (
          <>
            <Content className="contentStyle">
              <Row justify="center">
                <Col xs={24} style={{ height: "50px" }} />
                <Col xs={20}>
                  <Typography
                    style={{
                      fontFamily: "ralewayBold",
                      fontSize: "40px",
                      letterSpacing: " 0px",
                      color: "#074663",
                      opacity: 1,
                    }}
                  >
                    Get a Demo
                </Typography>

                  <Typography
                    style={{
                      fontFamily: "roboto",
                      fontSize: "16px",
                      color: "#646768",
                    }}
                  >
                    We are ready to set up free trial for you. No credit card
                    required. We will contact you in the contact details
                    provided below.
                </Typography>
                </Col>
              </Row>
            </Content>
            <Col xs={24} style={{ height: 50 }} />

            <Content className="contentStyle">
              <Row justify="center" style={{marginBottom: "20px"}}>
                <Col xs={24} sm={24}>
                <form>
                  <input type="hidden" name="contact_number" />

                  <div className="form-group">
                    <Label for="name" class="form-label">
                      Full Name
                    </Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Full Name"
                      name="name"
                      onChange={onChanged}
                      value={name}
                    />
                  </div>

                  <div className="form-group">
                    <Label for="email" class="form-label">
                      Email
                    </Label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={onChanged}
                      value={email}
                    />
                  </div>

                  <div className="form-group">
                    <Label for="phone" class="form-label">
                      Phone
                    </Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      onChange={onChanged}
                      value={phone}
                    />
                  </div>

                  <Row justify="flex-start" style={{marginBottom: "5px"}}>
                    <Col sm={20}>
                      <Row>
                        <Typography
                          style={{
                            fontfamily: "roboto",
                            fontSize: "16px",
                            color: "#646768",
                            marginTop: 0,
                          }}
                        >
                          Demo For :
                        </Typography>
                      </Row>
                      <Row style={{ height: 7 }} />
                      <Row>
                        {/* <Button 
                        name="inspureButton" 
                        style={buttonStyle}
                        onClick={onButtonSelect}
                        >
                          INSPIRE
                        </Button> */}
             
                        {/* <Button style={buttonStyle}>ASPIRE</Button>
                        <Button style={buttonStyle}>INSIGHT</Button> */}
                      </Row>
                    </Col>
                  </Row>

                  <div className="form-group">
                    <Label for="message" className="form-label">
                      Message
                    </Label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                      name="message"
                      onChange={onChanged}
                      value={message}
                    />
                  </div>

                  <input
                    type="submit"
                    value="Send"
                    class="btn btn-primary btn-block"
                  />
                  {/* <ToastContainer /> */}
                </form>
                </Col>

                <Col xs={14} justify= "flex-start">
                <Row style={{ margin: "15px 0px 10px 0px" }} justify="flex-start">
                  <Typography
                    style={{
                      fontFamily: "raleway",
                      fontSize: "20px",
                      color: "#074663",
                      textAlign: "left",
                    }}
                  >
                    Enterprises who trusts Us
                  </Typography>
                </Row>
                <Row justify="center">
                  <Col lg={6} style={{ margin: "15px 0px 10px 0px" }}>
                  <img
                    src={Kopila}
                    alt="logo"
                    style={{iconStyle}}
                  />
                  </Col>
                  <Col lg={6} style={{ margin: "15px 0px 10px 0px" }}>
                  <img
                    src={CSG}
                    alt="logo"
                    style={{iconStyle}}
                  />
                  </Col>
                  <Col lg={6} style={{ margin: "15px 0px 10px 0px" }}>
                  <img
                    src={SoalteeFoamHouse}
                    alt="logo"
                    style={{iconStyle}}
                  />
                  </Col>
                  <Col lg={7} style={{ margin: "15px 0px 10px 0px" }}>
                  <img
                    src={Karuna}
                    alt="logo"
                    style={{iconStyle}}
                  />
                  </Col>
                </Row>
              </Col>
              </Row>
            </Content>

          </>
        )}
    </div>
  );
}
export default ContactDemo;
