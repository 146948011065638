import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Button, Layout } from 'antd'


import CompanyFeatures from '../components/CompanyFeatures';
import Platforms from '../components/Platforms';
import ProductSection from '../components/productSection';

import landing from '../assets/landing.png';
import dots from '../assets/dots.png';
import GetADemo from '../components/GetADemo';
import * as ROUTES from "../routes";
import { Link } from "react-router-dom";

const { Content } = Layout;

const scrollToRef = (ref) => window.scrollTo(0, 2100) 
const scrollToRefs = (ref) => window.scrollTo(0, 4600) 
function Landing() {


    const [windowWidth, setWindowWidth] = useState("");
    const executeScroll = () => scrollToRef()
    const executeScrolls = () => scrollToRefs()

    useEffect(() => {

        setWindowWidth(window.innerWidth);

        

        window.addEventListener("resize", updateWindowSize);
    }, []);

    const updateWindowSize = () => {
        setWindowWidth(window.innerWidth);
    };

    return (
        <>

            <Row
                justify="center"
                style={{
                    backgroundColor: "#E5F3FE",
                    top: 0,
                    right: 0,
                    left: 0,
                    height: "650px",
                    width: "100%"
                }}>
                <Row style={{
                    width: "50%",
                    position: "absolute"
                }} justify="end">
                    <img
                        src={dots}
                        alt="SnigdhTech"
                        style={{
                            top: 200,
                            width: "300px",
                            height: "300px",
                            maxHeight: "100%",
                            cursor: "pointer",
                            marginTop: "109px",
                        }}
                    />
                </Row>
                <Col span={24}>
                    <Row justify="center">
                        {windowWidth > 500 ? (
                            <Col span={24} style={{marginTop: "100px" }}>
                                <Row justify="center">
                                    <Typography
                                        style={{
                                            fontSize: "60px",
                                            color: "#074663",
                                            textAlign: "center",
                                            fontFamily: "ralewayBold"
                                        }}
                                    >
                                        Think smart. Go beyond.
                                </Typography>
                                </Row>
                            </Col>
                        ) : (
                                <Col span={24} style={{ marginTop: "100px" }}>
                                    <Row justify="center">
                                        <Typography
                                            style={{
                                                fontSize: "40px",
                                                fontFamily: "ralewayBold",
                                                color: "#074663",
                                                textAlign: "center"
                                            }}
                                        >
                                            Think smart.
                                </Typography>
                                    </Row>
                                    <Row justify="center">
                                        <Typography
                                            style={{
                                                fontSize: "40px",
                                                fontFamily: "ralewayBold",
                                                color: "#074663",
                                                textAlign: "center"
                                            }}
                                        >
                                            Go beyond.
                                </Typography>
                                    </Row>
                                </Col>
                            )}



                    </Row>

                    <Row justify="center" style={{ marginTop: "15px" }}>
                        <Col xs={22} sm={18} style={{ maxWidth: "600px" }}>
                            <Typography
                                style={{
                                    color: "#646768",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    fontFamily: "roboto",
                                }} >
                                A Company for Software Development and Business Consulting Services, 
                                developing cloud based Enterprise Resources Planning (ERP) software, 
                                Management Information System (MIS) applications and providing specialized technical consultancies.                                
                                </Typography>
                        </Col>
                    </Row>
                    <Row style={{ width: "100%", marginBottom: "70px" }} justify="center">
                        {windowWidth > 500 ? (
                            <Row gutter={16} justify="center" style={{ width: "100%", maxWidth: "400px" }}>
                                <Col xs={12} style={{ marginTop: "20px", }}>
                                    <Row justify="center">
                                        <Button
                                            size="large"
                                            style={{ maxWidth: "250px" }}
                                            className="demoButton">
                                            <Link to={ROUTES.CONTACTDEMO}>Get a Demo</Link>
                                        </Button>
                                    </Row>
                                </Col>
                                <Col xs={12} style={{ marginTop: "20px", }}>
                                    <Row justify="center">
                                        <Button
                                            size="large"
                                            style={{ maxWidth: "250px" }}
                                           onClick={executeScroll}
                                            className="productButton"> Our Products
                                            
                                    </Button>
                                   
                                    </Row>
                                </Col>
                            </Row>
                        ) : (<Row gutter={16} justify="center" style={{ width: "100%", maxWidth: "400px" }}>
                            <Col xs={24} style={{ marginTop: "20px", }}>
                                <Row justify="center">
                                    <Button
                                        size="large"
                                        className="demoButton"
                                        style={{ width: "100%" }}>
                                        <Link to={ROUTES.CONTACTDEMO}>Get a Demo</Link>
                                    </Button>
                                </Row>
                            </Col>
                            <Col xs={24} style={{ marginTop: "20px", }}>
                                <Row justify="center">
                                   <Button
                                    size="large"
                                    style={{ width: "100%" }}
                                    onClick={executeScrolls}
                                    className="productButton"> Our Products
                                   </Button>
                                </Row>
                            </Col>
                        </Row>)}
                    </Row>
                </Col>
                <Col span={24}>
                    <Row justify="center" align="top">
                        <img
                            src={landing}
                            alt="SnigdhTech"
                            style={{
                                paddingRight: "10px", paddingLeft: "10px", position: "relative",
                                width: "100%",
                                maxWidth: "990px",
                                height: "auto",
                                maxHeight: "100%",
                                cursor: "pointer",
                                margin: "auto"
                            }}
                        />
                    </Row>
                </Col>


            </Row>




            <Col xs={0} sm={24} style={{ height: "100px" }} />
            <Content className="contentStyle" style={{ position: "relative", marginTop: "100px" }}>
                <Col style={{ maxWidth: "1000px", margin: "auto" }}>
                    <Row justify="center" style={{ width: "100%" }}>
                        <Col md={11} xs={24} style={{ marginTop: "60px" }}>
                            <Row justify="center">
                                <Typography
                                    style={{
                                        textAlign: "left",
                                        color: "#074663",
                                        fontSize: "26px",
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                    }}
                                >
                                   Quick, Flexible and User Friendly Software with the features for Customization and Personalization as your need
                </Typography>
                            </Row>
                        </Col>
                        <Col md={11} xs={24} style={{ paddingLeft: "25px" }}>
                            <Row justify="center" style={{ marginTop: "60px" }} >
                                <Typography
                                    style={{
                                        color: "#646768",
                                        fontSize: "16px",
                                        textAlign: "left",
                                        fontFamily: "roboto"
                                    }}
                                >
                                   Quick, flexible and user-friendly software built on universal standard that is easily adaptable, cost effective and little set up time to customers. Software come with handy user’s manuals and provides regular upgrades so that customers will have an up to date applications.
                                          </Typography>
                            </Row>
                            <Row justify="center" style={{ marginTop: "20px" }}>
                                <Typography
                                    style={{
                                        fontSize: "16px",
                                        color: "#505050",
                                        textAlign: "left",
                                        fontFamily: "roboto",
                                        fontWeight: 600
                                    }}
                                >
                                    User receives information at generic level that featured in and the information based on users’ different and unique preferences as well.                        </Typography>
                            </Row>
                        </Col>
                    </Row>
                </Col>

            </Content>
            <Row justify="end" style={{ height: "1000px", position: "absolute", width: "100%", zIndex: -1 }}>
                <Col justify="end" style={{ width: "auto", marginTop: "-550px", overflow: "hidden" }}>
                    <Typography
                        style={{
                            fontSize: "960px",
                            color: "#1A94F2",
                            opacity: "4%",
                            fontFamily: "ralewayBold",
                            marginRight: "-200px",
                            zIndex: -1,
                            transform: `rotate(-15deg)`
                        }}>
                        S
                </Typography>
                </Col>
            </Row>

            <Row style={{ marginTop: "100px" }}>
                <CompanyFeatures />
            </Row>
            <Row style={{ marginTop: "100px" }}>
                <Platforms />
            </Row>
            <Row style={{ marginTop: "100px" }}>
                <ProductSection />
            </Row>
            <GetADemo />

        </>
    )
}

export default Landing;
