import React, { useEffect, useState } from 'react';

import GetADemo from '../GetADemo';

import {
    Row,
    Col,
    Typography,
    Button,
    Layout
} from "antd";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid, 
} from "@material-ui/core";

import * as ROUTES from "../../routes";
import { Link } from "react-router-dom";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import dots from "../../assets/dots.png";
import ASPIRE_Header from "../../assets/Images/ASPIRE_Header.png";
import aspire_friendly from "../../assets/Images/aspire_friendly.png";
import aspire_quick from "../../assets/Images/aspire_quick.png";
import aspire_demand from "../../assets/Images/aspire_demand.png";

const { Content } = Layout;

function Aspire() {

    const [windowWidth, setWindowWidth] = useState("");

    useEffect(() => {

        setWindowWidth(window.innerWidth);

        window.addEventListener("resize", updateWindowSize);
    }, []);

    const updateWindowSize = () => {
        setWindowWidth(window.innerWidth);
    };

    const [panelExpanded, setPanelExpanded] = useState(false);

    const handlePanelChange = (_e, isExpanded) => {
        setPanelExpanded(isExpanded);
      };

    return (
        <div >

            <Row
                justify="center"
                style={{
                    backgroundColor: "#E5F3FE",
                    top: 0,
                    right: 0,
                    left: 0,
                    height: "400px",
                    width: "100%",
                    marginTop: "84px"
                }}>
                <Row style={{ width: "1%" }} justify="end">
                    <img
                        src={dots}
                        alt="SnigdhTech"
                        style={{
                            position: "absolute",
                            top: 200,
                            width: "300px",
                            height: "300px",
                            maxHeight: "100%",
                            cursor: "pointer",
                            marginTop: "9px",
                            marginLeft: "-200px"
                        }}
                    />
                </Row>
                <Col span={24} style={{ marginTop: "40px" }}>
                    <Row justify="center">

                        <Col
                            style={{
                                backgroundColor: "#FFFFFF",
                                paddingTop: "8px",
                                paddingBottom: "10px",
                                width: "156px",
                                height: "42px",
                                borderRadius: "30px"
                            }}>
                            <Typography style={{
                                textAlign: "center", color: "#1A94F2", fontFamily: "roboto",
                                fontWeight: 600, fontSize: "18px"
                            }}>
                                ASPIRE
                            </Typography>
                        </Col>
                    </Row>

                    <Row justify="center" style={{ marginTop: "15px" }}>

                        <Col xs={22} sm={18} style={{ maxWidth: "900px" }}>
                            {windowWidth > 690 ? (
                                <Typography
                                    style={{
                                        color: "#074663",
                                        textAlign: "center",
                                        fontSize: "20px",
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                    }} >
                                   A quick, flexible and user friendly cloud based ERP software. Snigdh ASPIRE synchronizes different business processes, increases efficiency and ensures operating effectiveness. ASPIRE includes general accounting, financial management, supply chain management, inventory management, project management, analysis and reporting with Key Performance Indicators (KPI) dashboard. It has been built as a robust system with difference dimensions and parameter so that user can do comprehensive planning, monitoring, reporting and control at difference level.
                                </Typography>) :
                                (<Typography
                                    style={{
                                        color: "#074663",
                                        textAlign: "center",
                                        fontSize: "14px",
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                    }} >
                                   A quick, flexible and user friendly cloud based ERP software. Snigdh ASPIRE synchronizes different business processes, increases efficiency and ensures operating effectiveness. ASPIRE includes general accounting, financial management, supply chain management, inventory management, project management, analysis and reporting with Key Performance Indicators (KPI) dashboard. It has been built as a robust system with difference dimensions and parameter so that user can do comprehensive planning, monitoring, reporting and control at difference level.
                                </Typography>)}
                        </Col>
                    </Row>
                    <Row style={{ width: "100%", height: "50px" }} justify="center">
                        <Row gutter={16} justify="center" style={{ width: "100%", maxWidth: "400px" }}>
                            <Col xs={24} style={{ marginTop: "20px", }}>
                                <Row justify="center">
                                    <Button
                                        size="large"
                                        className="demoButton">
                                             <Link to={ROUTES.CONTACTDEMO}>Get a Demo</Link>
                                       
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Col xs={0} sm={24} style={{ zIndex: -1, position: "absolute", height: "350px", width: "100%", backgroundColor: "#E5F3FE" }} />
                    </Row>

                </Col>
                <Col span={24}>
                    <Row align="top" justify="center" style={{ width: "100%", marginTop: "10px" }}>
                        <img
                            src={ASPIRE_Header}
                            alt="SnigdhTech"
                            style={{
                                position: "relative",
                                width: "99%",
                                maxWidth: "1000px",
                                height: "auto",
                                maxHeight: "100%",
                                cursor: "pointer",
                            }}
                        />
                    </Row>
                </Col>
            </Row>

            <Col xs={0} sm={24} style={{ height: "200px" }} />
            <Col xs={0} md={24} style={{ height: "200px" }} />
            <Row style={{ width: "100%" }} justify="center">

                <Col span={24}
                    style={{
                        width: "60%",
                        marginTop: "250px",
                        backgroundColor: "#E5F3FE",
                    }}>

                    <Row justify="center" style={{ width: "100%" }}>

                        <Content style={{ paddingTop: "50px", paddingBottom: "50px", maxWidth: "1000px" }}>

                            <Typography style={{
                                color: "#363636", fontSize: "26px", fontFamily: "raleway",
                                fontWeight: 600, marginBottom:"20px"
                                }}>
                              Snigdh ASPIRE offers an array of specific modules that include
                            </Typography>
                            {windowWidth > 575 ? (
                              <Row>
                             <Col sm={24} md={12}>
                                <Row xs={12} sm={6} style={{margin:"10px"}}>
                                <ExpansionPanel
                                expanded={panelExpanded}
                                onChange={handlePanelChange}
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel1"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        POS (Point of Sale) Billing
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                 POS is a software for the management of retail transaction at the time of sale. It creates invoice for the customer as soon as transaction completed. Core features includes are; Sales, Purchase, Sales Return, Purchase Return, Debit Note, Credit Note, Schemes, Loyalty, and Bill Printing. It integrates with Inventory and Accounting so that user can use this software in whole ERP platform for overall management of retail business.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    </Row>
                                    <Row xs={12} sm={6} style={{margin:"10px"}}>
                                <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel2"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                       Supply Chain Management
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                Supply Chain Management Module is to manage the flow of goods and services. It involves in the whole procurement process from sourcing method to movement, transfer and storage of items.  It performs core procurement process of Purchase Request, Purchase Order, Goods Received Note, Goods Issued Note and payment in automated manner.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    </Row>
                                    <Row xs={12} sm={6} style={{margin:"10px"}}>
                                <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel3"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        Inventory Management
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                Inventory Management Module is designed for tracking inventory level, managing orders, performing sales and deliveries. It includes: Item Catalogue, Warehouse/Store Management, Inventory Master Report, Inventory Transaction Details, Inventory Physical Count, Inventory closing report with stock value.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    </Row>
                                </Col>


                            <Col sm={24} md={12} style={{ width: "50%" }}>
                                <Row xs={12} sm={6} style={{margin:"10px"}}>
                                    <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                    >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel4"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                       Accounting
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                 A robust software for financial accounting and management system for accurate financial data for financial planning, reporting and control. The accounting software performs the regular financial operations and organizes all the information for analysis and reporting. It gathers financial data and provide results in transparent and accurate manner. As the software has been built considering segregation of duties and level of authority of the company, it makes the accounting team’s work easier according to different user roles.  
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                   </Row>
                                   <Row xs={12} sm={6} style={{margin:"10px"}}>
                                <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel5"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        Expenses Management
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                Integrated and automated system designs for expense management of companies. User can manage spending from multiple sources in one place. It helps to make intelligent business decisions based on accurate data, and simplify the process for everyone. Receipts and other supporting of expenses can upload and reimburse to employees can be done quickly.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                   </Row>
                                   <Row xs={12} sm={6} style={{margin:"10px"}}>
                                <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel6"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        Project Management System
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                 Project Management System (PMIS) is specifically designed for non-profit organization (NPO). It integrates Project Profile, Beneficiary Profile and Target vs. Achievements in one platform. Further it can be integrated with budget and detail implementation plan so that it makes sure that financial and program operations are inline. It helps to monitor program operation and make decisions on amendment based on accurate data from centralized system.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Row>
                            </Col>
                            </Row>):(
                                <Row>
                                {/* <Col sm={24} md={12}> */}
                                   <Row xs={12} sm={6} style={{margin:"10px"}}>
                                   <ExpansionPanel
                                   expanded={panelExpanded}
                                   onChange={handlePanelChange}
                                       style={{ maxWidth: "800px" }}
                                   >
                                       <ExpansionPanelSummary
                                       expandIcon={<ExpandMoreIcon />}
                                       id="panel1"
                                       >
                                       <Typography style={{
                                           fontFamily: "roboto",
                                           fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                       }}>
                                           POS (Point of Sale) Billing
                                       </Typography>
                                       </ExpansionPanelSummary>
                                       <ExpansionPanelDetails>
                                           <Grid container justify="center">
                                               <Grid item xs={12}>
                                                <Typography style={{
                                                  fontFamily: "roboto",
                                                  fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                                 }}>
                                                    POS is a software for the management of retail transaction at the time of sale. It creates invoice for the customer as soon as transaction completed. Core features includes are; Sales, Purchase, Sales Return, Purchase Return, Debit Note, Credit Note, Schemes, Loyalty, and Bill Printing. It integrates with Inventory and Accounting so that user can use this software in whole ERP platform for overall management of retail business.
                                                </Typography>
                                               </Grid>
                                           </Grid>
                                       </ExpansionPanelDetails>
                                       </ExpansionPanel>
                                       </Row>
                                       <Row xs={12} sm={6} style={{margin:"10px"}}>
                                   <ExpansionPanel
                                       style={{ maxWidth: "800px" }}
                                   >
                                       <ExpansionPanelSummary
                                       expandIcon={<ExpandMoreIcon />}
                                       id="panel2"
                                       >
                                       <Typography style={{
                                           fontFamily: "roboto",
                                           fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                       }}>
                                          Supply Chain Management
                                       </Typography>
                                       </ExpansionPanelSummary>
                                       <ExpansionPanelDetails>
                                       <Grid container justify="center">
                                               <Grid item xs={12}>
                                                <Typography style={{
                                                  fontFamily: "roboto",
                                                  fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                                 }}>
                                                   Supply Chain Management Module is to manage the flow of goods and services. It involves in the whole procurement process from sourcing method to movement, transfer and storage of items.  It performs core procurement process of Purchase Request, Purchase Order, Goods Received Note, Goods Issued Note and payment in automated manner.
                                                </Typography>
                                               </Grid>
                                           </Grid>
                                       </ExpansionPanelDetails>
                                       </ExpansionPanel>
                                       </Row>
                                       <Row xs={12} sm={6} style={{margin:"10px"}}>
                                   <ExpansionPanel
                                       style={{ maxWidth: "800px" }}
                                   >
                                       <ExpansionPanelSummary
                                       expandIcon={<ExpandMoreIcon />}
                                       id="panel3"
                                       >
                                       <Typography style={{
                                           fontFamily: "roboto",
                                           fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                       }}>
                                           Inventory Management
                                       </Typography>
                                       </ExpansionPanelSummary>
                                       <ExpansionPanelDetails>
                                       <Grid container justify="center">
                                               <Grid item xs={12}>
                                                <Typography style={{
                                                  fontFamily: "roboto",
                                                  fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                                 }}>
                                                   Inventory Management Module is designed for tracking inventory level, managing orders, performing sales and deliveries. It includes: Item Catalogue, Warehouse/Store Management, Inventory Master Report, Inventory Transaction Details, Inventory Physical Count, Inventory closing report with stock value.
                                                </Typography>
                                               </Grid>
                                           </Grid>
                                       </ExpansionPanelDetails>
                                       </ExpansionPanel>
                                       </Row>
                                   {/* </Col>
                               <Col sm={24} md={12} style={{ width: "50%" }}> */}
                                   <Row xs={12} sm={6} style={{margin:"10px"}}>
                                       <ExpansionPanel
                                       style={{ maxWidth: "800px" }}
                                       >
                                       <ExpansionPanelSummary
                                       expandIcon={<ExpandMoreIcon />}
                                       id="panel4"
                                       >
                                       <Typography style={{
                                           fontFamily: "roboto",
                                           fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                       }}>
                                          Accounting
                                       </Typography>
                                       </ExpansionPanelSummary>
                                       <ExpansionPanelDetails>
                                       <Grid container justify="center">
                                               <Grid item xs={12}>
                                                <Typography style={{
                                                  fontFamily: "roboto",
                                                  fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                                 }}>
                                                    A robust software for financial accounting and management system for accurate financial data for financial planning, reporting and control. The accounting software performs the regular financial operations and organizes all the information for analysis and reporting. It gathers financial data and provide results in transparent and accurate manner. As the software has been built considering segregation of duties and level of authority of the company, it makes the accounting team’s work easier according to different user roles.  
                                                </Typography>
                                               </Grid>
                                           </Grid>
                                       </ExpansionPanelDetails>
                                       </ExpansionPanel>
                                      </Row>
                                      <Row xs={12} sm={6} style={{margin:"10px"}}>
                                   <ExpansionPanel
                                       style={{ maxWidth: "800px" }}
                                   >
                                       <ExpansionPanelSummary
                                       expandIcon={<ExpandMoreIcon />}
                                       id="panel5"
                                       >
                                       <Typography style={{
                                           fontFamily: "roboto",
                                           fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                       }}>
                                           Expenses Management
                                       </Typography>
                                       </ExpansionPanelSummary>
                                       <ExpansionPanelDetails>
                                       <Grid container justify="center">
                                               <Grid item xs={12}>
                                                <Typography style={{
                                                  fontFamily: "roboto",
                                                  fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                                 }}>
                                                   Integrated and automated system designs for expense management of companies. User can manage spending from multiple sources in one place. It helps to make intelligent business decisions based on accurate data, and simplify the process for everyone. Receipts and other supporting of expenses can upload and reimburse to employees can be done quickly.
                                                </Typography>
                                               </Grid>
                                           </Grid>
                                       </ExpansionPanelDetails>
                                       </ExpansionPanel>
                                      </Row>
                                      <Row xs={12} sm={6} style={{margin:"10px"}}>
                                   <ExpansionPanel
                                       style={{ maxWidth: "800px" }}
                                   >
                                       <ExpansionPanelSummary
                                       expandIcon={<ExpandMoreIcon />}
                                       id="panel6"
                                       >
                                       <Typography style={{
                                           fontFamily: "roboto",
                                           fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                       }}>
                                           Project Management System
                                       </Typography>
                                       </ExpansionPanelSummary>
                                       <ExpansionPanelDetails>
                                       <Grid container justify="center">
                                               <Grid item xs={12}>
                                                <Typography style={{
                                                  fontFamily: "roboto",
                                                  fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                                 }}>
                                                    Project Management System (PMIS) is specifically designed for non-profit organization (NPO). It integrates Project Profile, Beneficiary Profile and Target vs. Achievements in one platform. Further it can be integrated with budget and detail implementation plan so that it makes sure that financial and program operations are inline. It helps to monitor program operation and make decisions on amendment based on accurate data from centralized system.
                                                </Typography>
                                               </Grid>
                                           </Grid>
                                       </ExpansionPanelDetails>
                                       </ExpansionPanel>
                                   </Row>
                               {/* </Col> */}
                               </Row> 
                            )}                  
                        </Content>

                    </Row>



                </Col>
            </Row>

            <Row style={{ marginTop: "100px" }} justify="center">
                <Typography style={{
                    color: "#074663", fontFamily: "raleway",
                    fontWeight: 600, fontSize: "40px", textAlign: "center"
                }}>
                    Why to Choose ASPIRE?
                </Typography>
            </Row>
            <Row style={{ marginTop: "20px"}} justify="center">
            <Col xs={22} sm={18} style={{ maxWidth:"900px"}}>
                {windowWidth > 690 ? (
            <Typography
            style={{
                color: "#646768",
                fontSize: "16px",
                textAlign: "center",
                fontFamily: "roboto"
            }}
            >
              Snigdh ASPIRE synchronizes different business processes, 
              increases efficiency and ensures operating effectiveness. 
              It integrates accounting, supply chain, inventory, expenses 
              management and other modules in single platform which helps to 
              identify and prevent errors – accuracy and automatically checks 
              error validation business mapping. It has been built as a robust 
              system with different dimensions and parameter so that user can do 
              comprehensive planning, monitoring, reporting and control as per Key 
              Performance Indicator at difference level. It secure sensitive data with 
              providing complete and solid security features. 
        </Typography>):(
            <Typography
            style={{
                color: "#646768",
                fontSize: "14px",
                textAlign: "center",
                fontFamily: "roboto",
            }}
            >
              Snigdh ASPIRE synchronizes different business processes, 
              increases efficiency and ensures operating effectiveness. 
              It integrates accounting, supply chain, inventory, expenses 
              management and other modules in single platform which helps to 
              identify and prevent errors – accuracy and automatically checks 
              error validation business mapping. It has been built as a robust 
              system with different dimensions and parameter so that user can do 
              comprehensive planning, monitoring, reporting and control as per Key 
              Performance Indicator at difference level. It secure sensitive data with 
              providing complete and solid security features. 
        </Typography> )}
            </Col>
            </Row>
            <Row justify="center" style={{ marginTop: "70px" }}>
                <Content className="contentStyle">
                    <Row>
                        <Col lg={12} xs={24}>
                            <Col>
                                <Row justify="center" style={{ width: "100%" }}>
                                    <Col xs={24} style={{ marginTop: "60px", paddingLeft: "15px", paddingRight: "15px" }}>
                                        <Row justify="center">
                                            <Typography
                                                style={{
                                                    textAlign: "left",
                                                    color: "#074663",
                                                    fontSize: "26px",
                                                    fontFamily: "raleway",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                User-friendliness
                </Typography>
                                        </Row>
                                    </Col>
                                    <Col xs={24} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                        <Row justify="center" style={{ marginTop: "20px" }} >
                                            <Typography
                                                style={{
                                                    color: "#646768",
                                                    fontSize: "16px",
                                                    textAlign: "left",
                                                    fontFamily: "roboto"
                                                }}
                                            >
                                                ASPIRE is designed for different level of users based on segregation of duties. Easy to adapt to different circumstances with aesthetic appealing, menu driven, easy to navigate and user friendly interfaces which makes easy for end users to work with. Different options available with multi-dimensional features for flexibility so that users can customize according to business requirement.
                        </Typography>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col xs={24} lg={12} style={{ padding: "20px" }}>
                            <img
                                src={aspire_friendly}
                                alt="SnigdhTech"
                                style={{

                                    width: "100%",
                                    height: "auto",
                                }}
                            />
                        </Col>

                    </Row>
                </Content>

            </Row>

            <Row justify="center" style={{ marginTop: "70px" }}>
                <Content className="contentStyle">
                    <Row>

                        <Col xs={24} lg={12} style={{ paddingTop: "20px", paddingRight: "20px", paddingBottom: "20px" }}>
                            <img
                                src={aspire_quick}
                                alt="SnigdhTech"
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    marginLeft: "-25px"
                                }}
                            />
                        </Col>
                        <Col lg={12} xs={24}>
                            <Row justify="center" style={{ width: "100%" }}>
                                <Col xs={24} style={{ marginTop: "60px", paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row justify="center">
                                        <Typography
                                            style={{
                                                textAlign: "left",
                                                color: "#074663",
                                                fontSize: "26px",
                                                fontFamily: "raleway",
                                                fontWeight: 600,
                                            }}
                                        >
                                            Quick and Smart
                                </Typography>
                                    </Row>
                                </Col>
                                <Col xs={24} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row justify="center" style={{ marginTop: "20px" }} >
                                        <Typography
                                            style={{
                                                color: "#646768",
                                                fontSize: "16px",
                                                textAlign: "left",
                                                fontFamily: "roboto"
                                            }}
                                        >
                                           Development of software in whole ERP concept. With using advance technology and adopting agile methodology, software operates quickly to manage business processes automatically and responsive to devices, premise or cloud.
                        </Typography>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>


                    </Row>
                </Content>

            </Row>

            <Row justify="center" style={{ marginTop: "70px" }}>
                <Content className="contentStyle">
                    <Row style={{ width: "60%" }} justify="end">
                        <img
                            src={dots}
                            alt="SnigdhTech"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "300px",
                                height: "300px",
                                maxHeight: "100%",
                                cursor: "pointer",
                                marginTop: "-100px",
                                marginBottom: "-100px",
                                zIndex: -1
                            }}
                        />
                    </Row>
                    <Row>

                        <Col lg={12} xs={24}>
                            <Row justify="center" style={{ width: "100%" }}>
                                <Col xs={24} style={{ marginTop: "60px", paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row justify="center">
                                        <Typography
                                            style={{
                                                textAlign: "left",
                                                color: "#074663",
                                                fontSize: "26px",
                                                fontFamily: "raleway",
                                                fontWeight: 600,
                                            }}
                                        >
                                            On demand use price - affordable from small enterprises to corporate
                                            </Typography>
                                    </Row>
                                </Col>
                                <Col xs={24} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row justify="center" style={{ marginTop: "20px" }} >
                                        <Typography
                                            style={{
                                                color: "#646768",
                                                fontSize: "16px",
                                                textAlign: "left",
                                                fontFamily: "roboto"
                                            }}
                                        >
                                            ASPIRE is available in basic, standard and premium versions and series for small enterprises and corporates. It is compatible to profit making and non-profit making organization as well. User can go live with whole ASPIRE ERP software or choose only a module according to their business requirement. The price depends upon version and series which is affordable to each type and size of business. 
                                        </Typography>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} lg={12} style={{ padding: "20px" }}>
                            <img
                                src={aspire_demand}
                                alt="SnigdhTech"
                                style={{

                                    width: "100%",
                                    height: "auto",
                                }}
                            />
                        </Col>
                    </Row>
                </Content>

            </Row>
            <GetADemo />
        </div >
    )
}

export default Aspire
