import React from 'react';
import { Row, Col, Typography, Layout } from 'antd';

import aws from '../assets/aws.png';
import java from '../assets/java.png';
import react from '../assets/react.png';
import sql from '../assets/sql.png';
import spring from '../assets/spring.png';

const { Content } = Layout;


function Platforms() {
    return (
        <>
            <Row style={{ width: "100%", backgroundColor: "#EDF6FD", paddingTop: "40px" }} >
                <Content className="contentStyle">
                    <Row justify="center" style={{ width: "100%", padding: "20px" }}>
                        <Col xs={24} sm={18}>
                            <Row justify="center" style={{ width: "100%" }}>
                                <Col xs={0} md={24} style={{ maxWidth: "450px" }}>

                                    <Typography
                                        style={{
                                            fontSize: "40px",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                            color: "#074663",
                                            textAlign: "center",
                                            lineHeight: "111%"
                                        }}
                                    >
                                        Some of the platforms that we use
                                </Typography>

                                </Col>
                                <Col xs={24} md={0}>
                                    <Typography
                                        style={{
                                            fontSize: "30px",
                                            fontFamily: "raleway",
                                            fontWeight: 600,
                                            color: "#074663",
                                            textAlign: "center",
                                            lineHeight: "111%"
                                        }}
                                    >
                                        Some of the platforms that we use
                                </Typography>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Col xs={24} style={{ paddingRight: "50px", paddingLeft: "50px", paddingBottom: "50px" }}>
                        <Row gutter={90} justify="center" >
                            <Col style={{ marginTop: "30px" }}>
                                <img
                                    src={spring}
                                    alt="SnigdhTech"
                                    style={{
                                        height: "44px",
                                        width: "171px",
                                        cursor: "pointer",
                                        marginTop: "17px",
                                    }}
                                />
                            </Col>
                            <Col style={{ marginTop: "30px" }}>
                                <img
                                    src={react}
                                    alt="SnigdhTech"
                                    style={{
                                        height: "74px",
                                        width: "69px",
                                        cursor: "pointer"
                                    }}
                                />
                            </Col>
                            <Col style={{ marginTop: "30px" }}>
                                <img
                                    src={sql}
                                    alt="SnigdhTech"
                                    style={{
                                        height: "97px",
                                        width: "139px",
                                        cursor: "pointer",
                                        marginTop: "-15px"
                                    }}
                                />
                            </Col>
                            <Col style={{ marginTop: "30px" }}>
                                <img
                                    src={java}
                                    alt="SnigdhTech"
                                    style={{
                                        height: "64px",
                                        width: "142px",
                                        cursor: "pointer",
                                        marginTop: "9px",
                                    }}
                                />
                            </Col>
                            <Col style={{ marginTop: "30px" }}>
                                <img
                                    src={aws}
                                    alt="SnigdhTech"
                                    style={{
                                        height: "54px",
                                        width: "90px",
                                        cursor: "pointer",
                                        marginTop: "14px",
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Content>
            </Row>
        </>
    )
}


export default Platforms
