import React from 'react'

import { Button, Row, Col, Typography } from "antd";

import whitedots from '../assets/whitedots.png';
import * as ROUTES from '../routes';
import { Link } from 'react-router-dom'

function GetADemo() {
    return (
        <Row
            justify="center"
            style={{
                marginTop: "100px",
                height: "435px",
                //width: "435px",
                background: "transparent linear-gradient(358deg, #2383CC 0%, #1D6BA7 100%) 0% 0% no-repeat padding-box",
                opacity: "87%",
            }}>
            <Col style={{ marginTop: "98px", padding: "10px" }}>
                <Row style={{ width: "30%" }} justify="end">
                    <img
                        src={whitedots}
                        alt="SnigdhTech"
                        style={{
                            position: "absolute",
                            top: 0,
                            width: "250px",
                            height: "250px",
                            maxHeight: "100%",
                            cursor: "pointer",
                            marginTop: "-40px",
                        }}
                    />
                </Row>
                <Row justify="center" style={{ width: "100%" }}>
                    <Col style={{ maxWidth: "500px" }}>
                        <Typography
                            style={{
                                color: "#FFFFFF",
                                fontSize: "38px",
                                textAlign: "center",
                                fontFamily: "raleway",
                                fontWeight: 600,
                                lineHeight: "111%"
                            }}
                        >
                            Ready to take your business started with us?
                    </Typography>
                    </Col>
                </Row>
                <Row justify="center" style={{ width: "100%", marginTop: "18px" }} >
                    <Col style={{ maxWidth: "350px" }}>
                        <Typography
                            style={{
                                color: "#FFFFFF",
                                fontSize: "14px",
                                textAlign: "center",
                                fontFamily: "roboto",
                            }}
                        >
                            Request for demo, It is fast, easy and completely free
                        </Typography>
                    </Col>
                </Row>
                <Row justify="center" style={{ width: "100%", marginTop: "20px" }} >
                    <Col style={{ maxWidth: "350px" }}>
                        <Button
                            size="large"
                            className="getDemo"
                        >
                            <Link to={ROUTES.CONTACTDEMO}>
                                Get a Demo
                                </Link>
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default GetADemo




