import React, { useEffect, useState } from 'react';

import GetADemo from '../GetADemo';

import {
    Row,
    Col,
    Typography,
    Button,
    Layout
} from "antd";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid, 
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import dots from "../../assets/dots.png";
import INSPIRE_Header from "../../assets/Images/INSPIRE_Header.png";
import inspire_hrms from "../../assets/Images/inspire_hrms.png";
import inspire_customization from "../../assets/Images/inspire_customization.png";
import inspire_CustomizationOnDemand from "../../assets/Images/inspire_CustomizationOnDemand.png";
import * as ROUTES from "../../routes";
import { Link } from "react-router-dom";

const { Content } = Layout;

function Inspire() {

    const [windowWidth, setWindowWidth] = useState("");

    useEffect(() => {
        setWindowWidth(window.innerWidth);

        window.addEventListener("resize", updateWindowSize);
    }, []);

    const updateWindowSize = () => {
        setWindowWidth(window.innerWidth);
    };

    
  const [panelExpanded, setPanelExpanded] = useState(false);

    const handlePanelChange = (_e, isExpanded) => {
        setPanelExpanded(isExpanded);
      };

    return (
        <>

            <Row
                justify="center"
                style={{
                    backgroundColor: "#E5F3FE",
                    top: 0,
                    right: 0,
                    left: 0,
                    height: "400px",
                    width: "100%",
                    marginTop: "84px"
                }}>
                <Row style={{ width: "1%" }} justify="end">
                    <img
                        src={dots}
                        alt="SnigdhTech"
                        style={{
                            position: "absolute",
                            top: 200,
                            width: "300px",
                            height: "300px",
                            maxHeight: "100%",
                            cursor: "pointer",
                            marginTop: "9px",
                            marginLeft: "-200px"
                        }}
                    />
                </Row>
                <Col span={24} style={{ marginTop: "40px" }}>
                    <Row justify="center">

                        <Col
                            style={{
                                backgroundColor: "#FFFFFF",
                                paddingTop: "8px",
                                paddingBottom: "10px",
                                width: "156px",
                                height: "42px",
                                borderRadius: "30px"
                            }}>
                            <Typography style={{
                                textAlign: "center", color: "#1A94F2", fontFamily: "roboto",
                                fontWeight: 600, fontSize: "18px"
                            }}>
                                INSPIRE
                            </Typography>
                        </Col>
                    </Row>

                    <Row justify="center" style={{ marginTop: "15px" }}>

                        <Col xs={22} sm={18} style={{ maxWidth: "900px" }}>
                            {windowWidth > 690 ? (
                                <Typography
                                    style={{
                                        color: "#074663",
                                        textAlign: "center",
                                        fontSize: "20px",
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                    }} >
                                   A secure, efficient and user friendly cloud based ERP software for people management and HR analytics. INSPIRE software is an integrated system designed to simplify human resources management from acquisition, development, utilization and retention. It processes inserted data automatically, and help the company pull off desired stats without using other applications and doing manual work.
                                </Typography>) :
                                (<Typography
                                    style={{
                                        color: "#074663",
                                        textAlign: "center",
                                        fontSize: "14px",
                                        fontFamily: "raleway",
                                        fontWeight: 600,
                                    }} >
                                   A secure, efficient and user friendly cloud based ERP software for people management and HR analytics. INSPIRE software is an integrated system designed to simplify human resources management from acquisition, development, utilization and retention. It processes inserted data automatically, and help the company pull off desired stats without using other applications and doing manual work.
                                </Typography>)}
                        </Col>
                    </Row>
                    <Row style={{ width: "100%", height: "50px" }} justify="center">
                        <Row gutter={16} justify="center" style={{ width: "100%", maxWidth: "400px" }}>
                            <Col xs={24} style={{ marginTop: "20px", }}>
                                <Row justify="center">
                                    <Button
                                        size="large"
                                        className="demoButton">
                                         <Link to={ROUTES.CONTACTDEMO}>Get a Demo</Link>
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Col xs={0} sm={24} style={{ zIndex: -1, position: "absolute", height: "350px", width: "100%", backgroundColor: "#E5F3FE" }} />
                    </Row>

                </Col>
                <Col span={24}>
                    <Row align="top" justify="center" style={{ width: "100%", marginTop: "80px" }}>
                        <img
                            src={INSPIRE_Header}
                            alt="SnigdhTech"
                            style={{
                                position: "relative",
                                width: "75%",
                                maxWidth: "1000px",
                                height: "auto",
                                maxHeight: "100%",
                                cursor: "pointer",
                            }}
                        />
                    </Row>
                </Col>
            </Row>

            <Col xs={0} sm={24} style={{ height: "200px" }} />
            <Col xs={0} md={24} style={{ height: "200px" }} />
            <Row style={{ width: "100%" }} justify="center">

                <Col span={24}
                    style={{
                        width: "100%",
                        marginTop: "250px",
                        backgroundColor: "#E5F3FE",
                    }}>


                    <Row justify="center" style={{ width: "100%" }}>

                        <Content style={{ paddingTop: "50px", paddingBottom: "50px", maxWidth: "1000px" }}>

                            <Typography style={{
                                color: "#363636", fontSize: "26px", fontFamily: "raleway",
                                fontWeight: 600, marginBottom:"20px"
                            }}>
                                Snigdh INSPIRE offers an array of specific modules that include
                            </Typography>
                        <Row>
                            <Col sm={24} md={12}  justify="start">
                                <Row style={{margin:"10px"}}>
                                <ExpansionPanel
                                    expanded={panelExpanded}
                                    onChange={handlePanelChange}
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        HRMIS
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                 Human Resources Management Information System (HRMIS) is an integrated application which collects and manages employees’ data including dashboard for HR analytics in different dimensions. It provides the HR information in instant manner and helps management for efficient decision making through visual infography.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    </Row>

                                    <Row  style={{margin:"10px"}}>
                                <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        Attendance Management
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                 Attendance Management includes employee check in, check out in real time basis. Attendance report is accessible by individual, Line Manager and HR Administrator as well. Attendance can be done through application in software itself or integrate with biometric devices.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    </Row>

                                <Row style={{margin:"10px"}}>
                                 <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        Leave Management
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                Leave management categories the types of leave and manage with appropriate authorization process and control. Leave balances will be up to date and can be accessed by employee, HR and Line Manager.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    </Row>
                                    <Row  style={{margin:"10px"}}>
                                <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                       Travel Management
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                 Travel Management includes travel request form, authorization process and the information to related departments, i.e. Logistics for travel management, Finance for advance and budget review. It generates detail itinerary and reports related to the trip.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    </Row>
                                    <Row  style={{margin:"10px"}}>
                                <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        Movement Management
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                It manages local transportation, office vehicles and movement within working area.
                                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;           
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Row>
                             </Col>


                            <Col sm={24} md={12}  justify="end">
                                 <Row style={{margin:"10px"}}>
                                <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        Daily Activity Report
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                 It provides a report of all activity throughout the day. The labor effort distribution can be done on the basis of task, department and project as well.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    </Row>
                                   <Row  style={{margin:"10px"}}>
                                <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        Timesheet
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                 It records employees’ efforts in terms of time spent on task or projects in daily or accumulative in monthly basis. It tracks employee hours for accurate payroll and can be integrated with salary ledger. It will provide information to management for workforce planning and management.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    </Row>
                                   <Row style={{margin:"10px"}}>
                                <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        Payroll
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                 A secure, flexible and error-free application for management of employees’ salaries and benefits. All entitlement, applicable income tax, deductions and retirement benefits will be managed. It automates the payroll calculation, tracks and maintains all payment records.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    </Row>
                                   <Row style={{margin:"10px"}}>
                                <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        Performance Management
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                 Performance management is designed to communicate the corporate goal to employees and designate individual accountability towards the goal through tracking of the progress in the achievement of the goals assigned and evaluating their individual performance. One on one meeting with supervisor, peer 360 degree feedback, staff nomination for award and so on can be managed by this module.
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    </Row>
                                    <Row style={{margin:"10px"}}>
                                <ExpansionPanel
                                    style={{ maxWidth: "800px" }}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography style={{
                                        fontFamily: "roboto",
                                        fontWeight: 600, fontSize: "17px", fontAlign: "left"
                                    }}>
                                        Recruitment
                                    </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid container justify="center">
                                            <Grid item xs={12}>
                                             <Typography style={{
                                               fontFamily: "roboto",
                                               fontWeight: 500, fontSize: "16px", fontAlign: "left"
                                              }}>
                                                Recruitment Management System is designed to integrate staff hiring process automatically. It manages posting jobs, connection with applicants, analysis for short listing, rating and ranking applicants, scheduling interviews and selection with an offer. It creates dashboard for recruitment analytics and reporting of management. 
                                             </Typography>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    </Row>
                                    </Col>
                            </Row>
                        </Content>
                    </Row>
                </Col>
            </Row>

            <Row style={{ marginTop: "100px" }} justify="center">
                <Typography style={{
                    color: "#074663", fontFamily: "raleway",
                    fontWeight: 600, fontSize: "40px", textAlign: "center"
                }}>
                    Why to Choose Inspire?
                </Typography>
            </Row>
            <Row style={{ marginTop: "20px"}} justify="center">
            <Col xs={22} sm={18} style={{ maxWidth:"900px"}}>
                {windowWidth > 690 ? ( 
                    <Typography
                    style={{
                        color: "#646768",
                        fontSize: "16px",
                        fontFamily: "roboto",
                        textAlign:"center"
                    }}
                    >
                        Snigdh INSPIRE is a ready to use software that empowers organizations to 
                        manage human resources in most efficient and cost friendly manner. It is flexible
                        and can be customized as per the requirement of the company. INSPIRE contains 
                        group of modules designed to streamline human resources management – 
                        operations, review, reporting analysis. 
                    </Typography>):(
                    <Typography
                    style={{
                        color: "#646768",
                        fontSize: "14px",
                        fontFamily: "roboto",
                        textAlign:"center",
                    }}
                    >
                            Snigdh INSPIRE is a ready to use software that empowers organizations to 
                            manage human resources in most efficient and cost friendly manner. It is flexible
                            and can be customized as per the requirement of the company. INSPIRE contains 
                            group of modules designed to streamline human resources management – 
                            operations, review, reporting analysis. 
                    </Typography>)}
            </Col>
            </Row>
            <Row justify="center" style={{ marginTop: "70px" }}>
                <Content className="contentStyle">
                    <Row>
                        <Col lg={12} xs={24}>
                            <Col>
                                <Row justify="center" style={{ width: "100%" }}>
                                    <Col xs={24} style={{ marginTop: "60px", paddingLeft: "15px", paddingRight: "15px" }}>
                                        <Row justify="center">
                                            <Typography
                                                style={{
                                                    textAlign: "left",
                                                    color: "#074663",
                                                    fontSize: "26px",
                                                    fontFamily: "raleway",
                                                    fontWeight: 600,
                                                }}
                                            >
                                               One stop solution for People Management and Human Resources Analytics
                </Typography>
                                        </Row>
                                    </Col>
                                    <Col xs={24} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                        <Row justify="center" style={{ marginTop: "20px" }} >
                                            <Typography
                                                style={{
                                                    color: "#646768",
                                                    fontSize: "16px",
                                                    textAlign: "left",
                                                    fontFamily: "roboto"
                                                }}
                                            >
                                               INSPIRE is a software that allows users to track, manage and integrate core HR needs in automation platform. The platform has centralized employees database which is key to streamline the processes of an individual employee. In addition, it manages entire life cycle of an employee from acquisition, training and development, performance management, compensation to end settlement. As it integrates employees information and HR business processes, it provides HR analysis and reporting in quick manner.
                        </Typography>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col xs={24} lg={12} style={{ padding: "20px" }}>
                            <img
                                src={inspire_hrms}
                                alt="SnigdhTech"
                                style={{

                                    width: "100%",
                                    height: "auto",
                                }}
                            />
                        </Col>

                    </Row>
                </Content>

            </Row>

            <Row justify="center" style={{ marginTop: "70px" }}>
                <Content className="contentStyle">
                    <Row>

                        <Col xs={24} lg={12} style={{ paddingTop: "20px", paddingRight: "15px", paddingBottom: "15px" }}>
                            <img
                                src={inspire_customization}
                                alt="SnigdhTech"
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    marginLeft: "-25px"
                                }}
                            />
                        </Col>
                        <Col lg={12} xs={24}>
                            <Row justify="center" style={{ width: "100%" }}>
                                <Col xs={24} style={{ marginTop: "60px", paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row justify="center">
                                        <Typography
                                            style={{
                                                textAlign: "left",
                                                color: "#074663",
                                                fontSize: "26px",
                                                fontFamily: "raleway",
                                                fontWeight: 600,
                                            }}
                                        >
                                            With the features of customization and personalization of software as your need
                                </Typography>
                                    </Row>
                                </Col>
                                <Col xs={24} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row justify="center" style={{ marginTop: "20px" }} >
                                        <Typography
                                            style={{
                                                color: "#646768",
                                                fontSize: "16px",
                                                textAlign: "left",
                                                fontFamily: "roboto"
                                            }}
                                        >
                                           Quick, flexible and user-friendly software built on universal standard that is easily adaptable and little set up time to customers. The software can be customized according to company policy and as required by statutory law and regulations. Similarly, user can have personal experience through their own data and technology. Visualization and inforgraphics can be customized as per customer’s corporate theme.
                        </Typography>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>


                    </Row>
                </Content>

            </Row>

            <Row justify="center" style={{ marginTop: "70px" }}>
                <Content className="contentStyle">
                    <Row style={{ width: "60%" }} justify="end">
                        <img
                            src={dots}
                            alt="SnigdhTech"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "300px",
                                height: "300px",
                                maxHeight: "100%",
                                cursor: "pointer",
                                marginTop: "-100px",
                                marginBottom: "-100px",
                                zIndex: -1
                            }}
                        />
                    </Row>
                    <Row>

                        <Col lg={12} xs={24}>
                            <Row justify="center" style={{ width: "100%" }}>
                                <Col xs={24} style={{ marginTop: "60px", paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row justify="center">
                                        <Typography
                                            style={{
                                                textAlign: "left",
                                                color: "#074663",
                                                fontSize: "26px",
                                                fontFamily: "raleway",
                                                fontWeight: 600,
                                            }}
                                        >
                                            On demand use price - affordable from small enterprises to corporate
                                            </Typography>
                                    </Row>
                                </Col>
                                <Col xs={24} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                    <Row justify="center" style={{ marginTop: "20px" }} >
                                        <Typography
                                            style={{
                                                color: "#646768",
                                                fontSize: "16px",
                                                textAlign: "left",
                                                fontFamily: "roboto"
                                            }}
                                        >
                                            INSPIRE has been designed in basic, standard and premium versions as well small enterprises to corporate series. It is compatible to profit making and non-profit making organization. The prices will be based on version and series which is affordable to each type and size of business. 
                                        </Typography>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} lg={12} style={{ padding: "20px" }}>
                            <img
                                src={inspire_CustomizationOnDemand}
                                alt="SnigdhTech"
                                style={{

                                    width: "100%",
                                    height: "auto",
                                }}
                            />
                        </Col>
                    </Row>
                </Content>

            </Row>
            <GetADemo />
        </>
    )
}

export default Inspire
