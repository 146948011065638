export const LANDING = '/';
export const COMPANY = '/company';
export const INSPIRE = '/inspire';
export const ASPIRE = '/aspire';
export const INSIGHT = '/insight';
export const SERVICES = '/services';
export const CONTACT = '/contact';
export const CONTACTDEMO = '/contactDemo';
export const PRIVACYPOLICY = '/privacyPolicy';
export const FAQs = '/fAQs';
